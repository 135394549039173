import React, { useEffect } from 'react';
import { Box, Grid, Typography, Card, Container } from '@mui/material';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import ElectricMopedOutlinedIcon from '@mui/icons-material/ElectricMopedOutlined';
import { useDispatch } from 'react-redux';
import { getAdminStates } from '../../slices/news';
import newsService from '../../services/news.service';

const Dashboard = ({setLoader, show}) => {

  const [open, setOpen] = React.useState(false);
  const [modalte, setModalte] = React.useState(null);
  const dispatch = useDispatch();

  // const getStates = async() => {
  //   const stats = await newsService.getAdminStates();
  //   //console.log(stats)
  // }
  // useEffect(() => {
  
  // },[])

  return (
    // <Container className='content' sx={{mt: 13}} maxWidth="fluid">
    //   <Grid container spacing={3} sx={{mb: 2}}>
    //       <Grid item xs={3}>
    //         <Card className='dashCard dashCard1' sx={{boxShadow:'none', p: 3, border:'1px solid #cacaca'}}>
    //         <Box sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
    //         <Box>
    //           <Typography variant='h4' sx={{fontWeight:'bold'}}>100</Typography>
    //             <Typography variant='h6' sx={{mt: 1}}>New Users</Typography>
    //         </Box>
    //         <Box sx={{background:'#eef8ff', p: 1.5, borderRadius: 2}}>
    //           <PeopleAltOutlinedIcon sx={{fontSize: 30, color:'#0b4c7c'}}/>
    //           </Box>
    //           </Box>
    //         </Card>
    //       </Grid>
    //       <Grid item xs={3}>
    //         <Card className='dashCard dashCard2' sx={{boxShadow:'none', p: 3, border:'1px solid #cacaca'}}>
    //         <Box sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
    //         <Box>
    //           <Typography variant='h4' sx={{fontWeight:'bold'}}>200</Typography>
    //             <Typography variant='h6' sx={{mt: 1}}>Published News</Typography>
    //         </Box>
    //         <Box sx={{background:'#f0fff6', p: 1.5, borderRadius: 2}}>
    //           <ElectricMopedOutlinedIcon sx={{fontSize: 30, color:'#02bb4c'}}/>
    //           </Box>
    //           </Box>
    //         </Card>
    //       </Grid>
    //       <Grid item xs={3}>
    //         <Card className='dashCard dashCard3' sx={{boxShadow:'none', p: 3, border:'1px solid #cacaca'}}>
    //         <Box sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
    //         <Box>
    //           <Typography variant='h4' sx={{fontWeight:'bold'}}>300</Typography>
    //             <Typography variant='h6' sx={{mt: 1}}>Pending News</Typography>
    //         </Box>
    //         <Box sx={{background:'#d9efff', p: 1.5, borderRadius: 2}}>
    //           <InventoryOutlinedIcon sx={{fontSize: 30, color:'#0093ff'}}/>
    //           </Box>
    //           </Box>
    //         </Card>
    //       </Grid>
    //       <Grid item xs={3}>
    //         <Card className='dashCard dashCard4' sx={{boxShadow:'none', p: 3, border:'1px solid #cacaca'}}>
    //         <Box sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
    //         <Box>
    //           <Typography variant='h4' sx={{fontWeight:'bold'}}>400</Typography>
    //             <Typography variant='h6' sx={{mt: 1}}>Rejected News</Typography>
    //         </Box>
    //         <Box sx={{background:'#fef1ff', p: 1.5, borderRadius: 2}}>
    //           <ShoppingCartOutlinedIcon sx={{fontSize: 30, color:'#ec00ff'}}/>
    //           </Box>
    //           </Box>
    //         </Card>
    //       </Grid>
    //   </Grid>
    // </Container>
    <>Under construction</>
  );
}

export default Dashboard;