import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Box, Grid,IconButton } from '@mui/material';
import { Link ,useNavigate } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { updateaction, getnewsstories ,updatestories } from '../../slices/news';
import { useDispatch , useSelector} from 'react-redux';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import { setStatus } from '../../slices/Status';

export default function MediaCard({ val, setLoader, status, onEdit }) {
  const [subshow, setSubshow] = useState(false);
  const [newsid, setNewsid] = useState([]);
  const [modalOpen,setModalOpen]=useState(false);
  const [storiesList, setStoriesList] = useState({});
  const [selectedStory, setSelectedStory] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate(); 
  // const {storyNews: newsData} = useSelector((state) => state.news);
  const [newsdata, setNewsdata] = useState({
    category_id: '',
    multilingual_descriptions: {
      "en": "News Topic Description",
      "ta": "Descripción del tema de noticias"
    },
    multilingual_names: {
      en: "",
      ta: ""
    },
    name: "",
    news_ids: [
    ]
  })

  MediaCard.propTypes = {
    val: PropTypes.object.isRequired,
    onEdit: PropTypes.func.isRequired, // Add prop type for onEdit
  };

  const date = new Date(val?.created_at);
  const formattedDate = date.toLocaleDateString('en-GB', {
    day: '2-digit',
    month: 'short',
    year: 'numeric'
  });
  const formattedTime = date.toLocaleTimeString('en-GB', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false
  });

  const handleOption = () => {
    setSubshow(!subshow);
  };

  const handleAddToStoryClick = (formData) => {
    // //console.log(news_id)
    setModalOpen(true);

    setNewsid(formData);

    fetchStories(formData);
  };

  const handleEditClick = () => {
    onEdit(val); // Call the edit handler with the news item to edit
  };



  const handleAction = (news_id, val) => {
    //console.log(val)
    setLoader(true);
    dispatch(updateaction({ news_id, val }))
      .then(() => {
        setLoader(false);
        switch (val) {
          case "revoke":
            dispatch(setStatus("pending"));
          case "reject":
            dispatch(setStatus("rejected"));
          case "approve":
            dispatch(setStatus("approved"));
        }
      })
      .catch(() => {
        setLoader(false);
      });
      //console.log(val);
  };

  const handleAddToStories = (val) => {
    if (!selectedStory || !selectedStory.id) { 
      console.error("Selected story is not valid:", selectedStory);
      return;
    }
    
    //console.log(val?.id);
const previousids = selectedStory?.news.map((n) => n?.id);
    //console.log(previousids);
  
    // // Create updated story data by including the selected story and news ID
    const updatedStoryData = {
      ...selectedStory,
      // news: [...updatedNews, val], // Add the news item into the array
      news_ids: [...previousids, val?.id], // Add the news item ID to the news_ids array
    };
  
    //console.log("Updating stories with:", updatedStoryData);
    // //console.log(selectedStory?.id);
    // //console.log(val?.id);
    
    
  
    // Dispatch the updated story data
    setLoader(true);
    dispatch(updatestories({ id: selectedStory?.id, formData: updatedStoryData }))
      .then(() => {
        setLoader(false);
        navigate("/newsstories"); // Navigate to news stories page after success
      })
      .catch((err) => {
        //console.log("Failed to update story:", err);
        setLoader(false);
      });
  };
  


  const NextArrow = (props) => {
    const { onClick } = props;
    return (
      <ArrowForwardIosIcon
        onClick={onClick}
        style={{ right: '20px',bottom:'135px', position: 'absolute', zIndex: 2, cursor: 'pointer', color: 'black' }}
      />
    );
  };

  const PrevArrow = (props) => {
    const { onClick } = props;
    return (
      <ArrowBackIosIcon
        onClick={onClick}
        style={{ left: '25px',bottom:'135px', position: 'absolute', zIndex: 2, cursor: 'pointer', color: 'black' }}
      />
    );
  };

  const formData={
    "filter": {},
    "sort": "_id",
    "order": 1,
    "page": 0,
    "page_size": 10
  }
  const fetchStories = (formData) => {
    setLoader(true);
    dispatch(getnewsstories({ formData }))
  .then((response) => {
    //console.log("Full API response:", response); // Add this line
    //console.log(response?.payload?.data?.data?.data?.id);
    
    const fetchedStories = response?.payload?.data?.data?.data|| []; // Adjust based on actual response structure
    //console.log("Fetched stories:", fetchedStories);
    setStoriesList(fetchedStories);
    setLoader(false);
  })
  .catch((error) => {
    console.error("Error fetching stories:", error);
    setLoader(false);
  });
  };
  
  

  const sliderSettings = {
    // dots: false,
    // infinite: true,
    // slidesToShow: 1,
    // slidesToScroll: 1,
    // autoplay: true,
    // speed: 2000,
    // autoplaySpeed: 2000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <Grid item xs={3} md={3} lg={3}>
      <Card sx={{ maxWidth: '100%', height: '550px', boxShadow: 'none', border: '1px solid #cacaca', borderRadius: 3, p: 1 }}>
        <CardContent>
          <Box component="div" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="span">
              Category: {val?.category?.name}
            </Typography>
            {status === 'approved' && (
              <Button size='small' sx={{ position: 'relative', minWidth: 0, padding: 0 }} onClick={handleOption}>
                <MoreVertIcon />
                {subshow && (
                  <Box sx={{ position: 'absolute', bgcolor: '#fff', top: '30px', right: 0, boxShadow: '0px 0px 10px #cacaca', borderRadius: 2, zIndex: { xs: 10, sm: 20, md: 30, lg: 40 } }}>
                    <Box sx={{ py: 1, px: 2 }} onClick={()=>handleAddToStoryClick(val?.id)}>
                      <div style={{ display: "flex", justifyContent: 'center' }}>
                        <Typography sx={{ color: "#000", whiteSpace: 'nowrap' }}>
                          Add to Story
                        </Typography>
                      </div>
                    </Box>
                  </Box>
                )}
              </Button>
              )}
          </Box>
        </CardContent>
        <Slider {...sliderSettings}>
          <div>
          <CardMedia
             sx={{ height: 300,border:'1px solid gray',mx: 2,borderRadius: 3,display: 'flex',justifyContent: 'center',alignItems: 'center',}}
          >
            <img
              src={val?.news_card_images?.en?.low_res_url}
              alt="News Image 1"
                style={{ width: '80%', height: '80%', objectFit: 'cover', borderRadius: 'inherit' }}
            />
         </CardMedia>

          </div>
          <div>
          <CardMedia
             sx={{ height: 300,border:'1px solid gray',mx: 2,borderRadius: 3,display: 'flex',justifyContent: 'center',alignItems: 'center',}}
          >
            <img
              src={val?.news_card_images?.ta?.low_res_url}
              alt="News Image 2"
                style={{ width: '80%', height: '80%', objectFit: 'cover', borderRadius: 'inherit' }}
            />
         </CardMedia>

            {/* <CardMedia
              sx={{ height: 250, mx: 2, borderRadius: 3 }}
              image={val?.news_card_images?.ta?.low_res_url}
              title="News Image 2"
            /> */}
          </div>
        </Slider>
        <CardContent>
          <Typography variant="body2" color="text.secondary">
            {val?.news_source?.name} | {`${formattedDate} ${formattedTime}`}
          </Typography>
        </CardContent>
        <CardActions>
          {/* Revoke Actions */}
            {val?.actions?.map((cd, index) => {
              return (
                <Button key={index}
                  onClick={() => handleAction(val?.id, cd?.action)}
                  variant='outlined'
                  color={cd?.negative_action ? 'primary':'success'}
                  sx={{ width: '50%' }}
                >
                  {cd?.label}
                </Button>
              );
            })}
         </CardActions>

        <Link to={`editnews/${val?.id}`} style={{ textDecoration: 'none' }}>
           <Button variant='outlined' onClick={handleEditClick} color='inherit' sx={{ width: '95%', marginLeft:'8px'}}>Edit</Button>
        </Link>

        {/* Popup */}
        {modalOpen && (
  <Box
    sx={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      bgcolor: 'rgba(0, 0, 0, 0.6)', 
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1300, 
      transition: 'all 0.3s ease', 
    }}
  >
    <Box
      sx={{
        bgcolor: '#fff',
        p: { xs: 2, sm: 3, md: 4 }, borderRadius: 2, boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)', position: 'relative', width: { xs: '90%', sm: '400px', md: '500px' }, 
        maxWidth: '90%',  textAlign: 'center', 
      }}
    >
      {/* Modal Header */}
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h6" fontWeight="bold" color="primary">
          Add to Stories
        </Typography>
        <IconButton onClick={() => setModalOpen(false)} aria-label="close">
          <CloseIcon />
        </IconButton>
      </Box>

      {/* Modal Content */}
      <Typography variant="body1" color="text.secondary" mb={3}>
          Select a story to add:
      </Typography>

      {/* Modal Actions */}
      <Box>

      {Array.isArray(storiesList) && storiesList.length > 0 ? (
        storiesList.map((story, index) => (
          <Box
            key={index}
            sx={{ mb: 2,display:'flex',flexDirection:'row' }}
            onClick={() => setSelectedStory(story)} // Select the story when clicked
            style={{
              cursor: "pointer",
              backgroundColor: selectedStory === story ? "#f0f0f0" : "white", // Highlight selected story
            }}
          >
            <Typography variant="body2">{story?.name}</Typography>
          </Box>
        ))
      ) : (
        <Typography variant="body2" color="text.secondary">
          No stories available.
        </Typography>
      )}

      <Button
        variant="contained"
        onClick={() => handleAddToStories(val)} // Add the selected story
        color="primary"
        sx={{ mt: 1 }}
        disabled={!selectedStory} // Disable the button if no story is selected
      >
        Add
      </Button>

      </Box>
    </Box>
  </Box>
)}

      </Card>
    </Grid>
  );
}
