import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { TextField, Chip, Divider, FormLabel, RadioGroup, FormControlLabel, Radio, Container, Modal, Autocomplete,Dialog, DialogActions, DialogContent,DialogTitle, FormGroup, IconButton } from '@mui/material';
import CloudUpload from '../../components/button/Cloudupload';
import {Grid} from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';  
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import Editor from './Editor';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { createnews, deletenews, getcategories, getcity, getnewsad, getnewsbyid, getstates, gettopics,addTopics,updateTopics, updatenews,uploadTopicImage, uploadimage,newsTranslate } from '../../slices/news';
import { toast } from 'sonner';


const NewsNameData=[
  { id: 1, name: "International News" },
  { id: 2, name: "National News" },
  { id: 3, name: "State News" },
  { id: 4, name: "Local News" },
]
 
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  p: 2,
  borderRadius: 2
};



const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function Addnews({setLoader}) {

  const params = useParams();

  const [open, setOpen] = React.useState(false);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const {categories: catData} = useSelector((state) => state.news);

  const {topics: topData} = useSelector((state) => state.news);
  

  const {newsAd: adData} = useSelector((state) => state.news);

  const {cities: cityData} = useSelector((state) => state.news);

  const {states: stateData} = useSelector((state) => state.news);

  const [categoryName, setCategoryName] = React.useState(null);

  const [topicsname, setTopicsName] = React.useState(null);

  const [tanewsad, settaNewsAd] = React.useState(null);

  const [ennewsad, setenNewsAd] = React.useState(null);

  const [cityname, setCityName] = React.useState(null);

  const [statename, setStateName] = React.useState(null);

  const [file1, setFile1] = useState(null);

  const [file2, setFile2] = useState(null);

  // const [inputValue, setInputValue] = useState('');

  const [inputValueTa, setInputValueTa] = useState('');

  const [inputValueEn, setInputValueEn] = useState('');
  // const [hashtags, setHashtags] = useState([]);

  const [enHashtags, setenHashtags] = useState([]);

  const [taHashtags, settaHashtags] = useState([]);

  const [content1, setContent1] = useState('');

  const [content2, setContent2] = useState('');

  const [taTitle, setTaTitle] = useState("");
  const [enTitle, setEnTitle] = useState("");

  const [enMetaTitle,setEnMetaTitle] = useState("");

  const [taMetaTitle,setTaMetaTitle] = useState("");

   const [enmetadesc, setEnMetadesc] = useState("");
  
  const [tametadesc, setTaMetadesc] = useState("");

  const [openPopup, setOpenPopup] = useState(false); 
  const [newTopic, setNewTopic] = useState(""); 
  const [newsName,setNewsName] = useState(null);

  const isStateDisabled = newsName?.name === "International News" || newsName?.name === "National News" || newsName?.name === "Local News";
  const isCityDisabaled =  newsName?.name === "International News" || newsName?.name === "National News" || newsName?.name === "State News";
  const [isFocussed,setIsFocussed] = useState(null);

  const {newsid}= useParams();

  const [topics, setTopics] = useState([]);
  const [popup, setPopup] = useState(false);
  const [taTopicName, setTaTopicName] = useState('');
  const [enTopicName, setEnTopicName] = useState('');
  const [enTopicDesc, setEnTopicDesc] = useState('');
  const [taTopicDesc, setTaTopicDesc] = useState('');
  const [file, setFile] = useState(null);

  const [error, setError] = useState(false);

  const [page, setPage] = useState(1);
  const [bottomLoader, setbottomLoader] = useState(false);
  const [fetchedtopics, setFetchedTopics] = useState([]);

  console.log("topics:",topics);
  

  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedTopic, setSelectedTopic] = useState(null);
//  console.log("selectedTopic:",selectedTopic);

  // console.log("NewsName:",newsName);


  const [newsdata, setNewsdata] = useState({
        category_id:"", 
        city_id: "",
        created_at:"",
        content:{
          ta:{   
            headlines: "",
            title: taTitle,
            web_content: "",
            news_ad_id : "",
            meta_description:"",
            meta_title:"",
            tags:taHashtags,
            short_summary:"",
          },
          en:{
            headlines: "",
            title: enTitle,
            web_content: "",
            news_ad_id : "",
            meta_description:enmetadesc,
            meta_title:enMetaTitle,
            tags:enHashtags,
            short_summary:"",
          }
        },
        is_breaking: false,
        is_international_news: false,
        is_national_news:false,
        is_state_news: false,
        is_local_news:false,
        meta_description: tametadesc,
        meta_title: taMetaTitle,
        news_source:{
          name: "",
          url: ""
        },
        news_type: "web",
        state_id: "",
        // tags: hashtags,
        topics: [
          enTopicName?.id
        ]
  })

  console.log("newsdata:",newsdata?.content?.en)

  const date = new Date(newsdata?.created_at || Date.now());
  // console.log("date:",date);
  
  // const formattedDate = date.toLocaleDateString('en-GB', {
  //   day: '2-digit',
  //   month: 'short',
  //   year: 'numeric'
  // });
  // const formattedTime = date.toLocaleTimeString('en-GB', {
  //   hour: '2-digit',
  //   minute: '2-digit',
  //   hour12: false
  // });

  // const createdAt = new Date(); // Stores the current date and time
  // console.log("createdAt:",createdAt); // Outputs the current date and time
  // createdAt: Sat Nov 30 2024 14:18:20 GMT+0530 (India Standard Time)

  // const createdAt = new Date().toISOString();
  //  console.log("createdAt:",createdAt); // Outputs the date and time in ISO format, e.g., "2024-11-30T12:45:00.000Z"
  // createdAt: 2024-11-30T08:48:31.934Z

  // const createdAt = new Date().toLocaleString();
  //  console.log("createdAt:",createdAt); // Outputs the date and time in a human-readable format based on the local time zone
  //  createdAt: 30/11/2024, 2:20:48 pm

  const handleOpen = () => setPopup(true);
  const handleClose = () => {
    resetFields();
    setPopup(false);
  };

  const resetFields = () => {
    setTaTopicName('');
    setEnTopicName('');
    setEnTopicDesc('');
    setTaTopicDesc('');
    setFile(null);
    setIsEditMode(false);
  };
  const handleValue = (e, name) => {
    setNewsdata({
      ...newsdata,
      [name]: e.target.value,
    });

    if(name==='tametatitle'){
      setTaMetaTitle(e.target.value)
    }
    if(name==='enmetatitle'){
      setEnMetaTitle(e.target.value)
    }
    if(name==='enmetadescription'){
      setEnMetadesc(e.target.value)
    }
    if(name==='tametadescription'){
      setTaMetadesc(e.target.value)
    }
    if(name==='nsname'){
      setNewsdata({
        ...newsdata,
        news_source:
          {
          ...newsdata?.news_source,
          name: e.target.value
          },
      });
    }
    if(name==='nsurl'){
      setNewsdata({
        ...newsdata,
        news_source:
          {
          ...newsdata?.news_source,
          url: e.target.value
          },
      });
    }
    if(name==='tntitle'){
      setTaTitle(e.target.value);
    }
    if(name==='tnheadlines'){
      setNewsdata({
        ...newsdata,
        content:{
          ...newsdata.content,
          ta:{
            ...newsdata.content.ta,
            headlines: e.target.value
          }
        }
      });
    }
    if(name==='tnshort_summary'){
      setNewsdata({
        ...newsdata,
        content:{
          ...newsdata.content,
          ta:{
            ...newsdata.content.ta,
            short_summary: e.target.value
          }
        }
      });
    }
    if(name==='entitle'){
      setEnTitle(e.target.value)
    }
    if(name==='enheadlines'){
      setNewsdata({
        ...newsdata,
        content:{
          ...newsdata.content,
          en:{
            ...newsdata.content.en,
            headlines: e.target.value
          }
        }
      });
    }
    if(name==='enshort_summary'){
      setNewsdata({
        ...newsdata,
        content:{
          ...newsdata.content,
          en:{
            ...newsdata.content.en,
            short_summary: e.target.value
          }
        }
      });
    }
  }

  const handleInputChangeEn = (e) => {
    setInputValueEn(e.target.value);
  };
  
  const handleInputChangeTa = (e) => {
    setInputValueTa(e.target.value);
  };

  const handleInputKeyDownEn = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const value = inputValueEn.trim();
      if (value) {
        const tags = [...new Set(value.split(/[ ,]+/).map(tag => tag.trim()).filter(tag => tag))].map(tag => `${tag}`);
        setenHashtags(prevTags => {
          // console.log("Previous Tags: ", prevTags)
          return [...prevTags, ...tags]; // Always return a new array
        });
        setInputValueEn('');
      }
    }
  };
  const handleInputKeyDownTa = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const value = inputValueTa.trim();
       if (value) {
        const tags = [...new Set(value.split(/[ ,]+/).map(tag => tag.trim()).filter(tag => tag))].map(tag => `${tag}`);
        settaHashtags(prevTags => {
          // console.log("Previous Tags: ", prevTags)
          return [...prevTags, ...tags]; // Always return a new array
        });
        setInputValueTa('');
      }
    }
  };

  const handleDeleteChipEn = (index) => {
    const updatedHashtags = [...enHashtags];
    updatedHashtags.splice(index, 1);
    setenHashtags(updatedHashtags);
  };

  const handleDeleteChipTa = (index) => {
    const updatedHashtags = [...taHashtags];
    updatedHashtags.splice(index, 1);
    settaHashtags(updatedHashtags);
  };

  const handleCheckboxChange = (event) => {
    setNewsdata({
      ...newsdata,
      [event.target.name]: event.target.checked,
    });
  };

  const arrowBack = () => {
    navigate(-1);
  };

  const handleTranslate = (content, language, isShort = false) => {
    setLoader(true);
  
    const payload = { content, language };
  
    // Determine which action to dispatch based on the type of translation (web or short)
    const action = newsTranslate({ news_id: newsdata?.id, payload });
  
    dispatch(action)
      .unwrap()
      .then((response) => {
        const translatedContent = response?.data?.result || "";
  
        if (isShort) {
          setNewsdata((prevState) => ({
            ...prevState,
            content: {
              ...prevState.content,
              [language=='tamil'?'ta':language]: {
                ...prevState.content[language],
                short_summary: translatedContent,
              },
            },
          }));
        } else {
          if (language === "en") {
            setContent2(translatedContent); // For translating to English
          } else {
            setContent1(translatedContent); // For translating to Tamil
          }
        }
      })
      .catch((error) => {
        console.error("Translation Error:", error.message);
      })
      .finally(() => {
        setLoader(false);
      });
  };
  
  // Usage for web translation to Tamil:
  const handleTranslateWebTa = () => handleTranslate(content2, "tamil");
  
  // Usage for short translation to Tamil:
  const handleTranslateShortTa = () => handleTranslate(newsdata?.content?.en?.short_summary, "tamil", true);
  
  // Usage for web translation to English:
  const handleTranslateWebEn = () => handleTranslate(content1, "en");
  
  // Usage for short translation to English:
  const handleTranslateShortEn = () => handleTranslate(newsdata?.content?.ta?.short_summary, "en", true);

  // Meta Title
  const handleTranslateWebTitle = (content, language, setMetaTitle) => {
    setLoader(true);
  
    const payload = { content, language };
    dispatch(newsTranslate({ news_id: newsdata?.id, payload }))
      .unwrap()
      .then((response) => {
        const translatedContent = response?.data?.result || "";
        setMetaTitle(translatedContent);
        console.log("Translated Content:", response);
      })
      .catch((error) => {
        console.error("Translation Error:", error.message);
      })
      .finally(() => {
        setLoader(false);
      });
  };
  
  // Usage:
  const handleTranslateWebTaTitle = () =>
    handleTranslateWebTitle(enMetaTitle, "tamil", setTaMetaTitle);
  
  const handleTranslateWebEnTitle = () =>
    handleTranslateWebTitle(taMetaTitle, "en", setEnMetaTitle);

  // Meta Desc
  const handleTranslateWeb = (content, language, setMetaContent) => {
    setLoader(true);
  
    const payload = { content, language };
    
    dispatch(newsTranslate({ news_id: newsdata?.id, payload }))
      .unwrap()
      .then((response) => {
        const translatedContent = response?.data?.result || "";
        setMetaContent(translatedContent);
        console.log("Translated Content:", response);
      })
      .catch((error) => {
        console.error("Translation Error:", error.message);
      })
      .finally(() => {
        setLoader(false);
      });
  };
  
  // Usage:
  const handleTranslateWebTaDesc = () =>
    handleTranslateWeb(enmetadesc, "tamil", setTaMetadesc);
  
  const handleTranslateWebEnDesc = () =>
    handleTranslateWeb(tametadesc, "en", setEnMetadesc);

  // Title
  const handleTranslateWebTit = (content, language, setMetaContent) => {
    setLoader(true);
  
    const payload = { content, language };

    dispatch(newsTranslate({ news_id: newsdata?.id, payload }))
      .unwrap()
      .then((response) => {
        const translatedContent = response?.data?.result || "";
        setMetaContent(translatedContent);
        console.log("Translated Content:", response);
      })
      .catch((error) => {
        console.error("Translation Error:", error.message);
      })
      .finally(() => {
        setLoader(false);
      });
  };

   // Usage:
   const handleTranslateWebTaTit = () =>
    handleTranslateWebTit(enTitle, "tamil", setTaTitle);
  
  const handleTranslateWebEnTit = () =>
    handleTranslateWebTit(taTitle, "en", setEnTitle);

  useEffect(()=>{
    setLoader(true)
    const formData = {
      "filter": {},
      "sort": "_id",
      "order": 1,  
      "page": 0,
      "page_size": 100
    }
      dispatch(getcategories(formData))
      dispatch(gettopics(formData))
      // dispatch(addTopics(formData))
      dispatch(getnewsad(formData))
      dispatch(getcity())
      dispatch(getstates())
      // dispatch(getnewsbyid(params.newsid))
      .then((data) => {
        const upData = data.payload.newsdata.data
        console.log("upData:",upData);
        
        setNewsdata(upData);
        setCategoryName(upData?.category);
        
        // setTopicsName(newsdata?.topics?.length > 0 && newsdata?.topics[0]?.multilingual_names?.en);
        // setHashtags(upData.tags);
        setenHashtags(upData?.content?.en?.tags);
        settaHashtags(upData?.content?.ta?.tags);
        settaNewsAd(upData?.content?.ta?.news_ad);
        setenNewsAd(upData?.content?.en?.news_ad)
        setFile1(upData?.content?.ta?.images);
        setFile2(upData?.content?.en?.images);
        setContent1(upData?.content?.ta?.web_content);
        setContent2(upData?.content?.en?.web_content);
        // setMetatitle(upData?.meta_title);
        setTaMetaTitle(upData?.content?.ta?.meta_title);
        setEnMetaTitle(upData?.content?.en?.meta_title);
        // setMetadesc(upData?.meta_description);
        setTaMetadesc(upData?.content?.ta?.meta_description);
        setEnMetadesc(upData?.content?.en?.meta_description);

        setEnTitle(upData?.content?.en?.title)
        setTaTitle(upData?.content?.ta?.title)

        setLoader(false);
      })
      .catch(()=>{
        setLoader(false);
      })
  }, [])

  const fetchTopics = () => {
    const formData = {
      filter: {},
      sort: '_id',
      order: 1,
      page: page,
      page_size: 1000,
    };

    setbottomLoader(true);

    dispatch(gettopics(formData))
      .then((data) => {
        const fetchedTopics = data?.payload?.data?.data || [];
        setFetchedTopics(fetchedTopics?.data);
        // Append new topics to the existing list
        setTopics((prevTopics) => [...prevTopics, ...(fetchedTopics?.data || [])]);
      })
      .catch((error) => console.error('Error fetching topics:', error))
      .finally(() => setbottomLoader(false));
  };
  


    useEffect(() => {
      fetchTopics();
    }, [page]);


  const handleAddNewTopic = () => {
    if (taTopicName.trim() || enTopicName.trim()) {
      // Check for duplicate topics
    const isDuplicate = topics.some(
      (topic) =>
        topic.multilingual_names.en.toLowerCase() === enTopicName.trim().toLowerCase() ||
        topic.multilingual_names.ta.toLowerCase() === taTopicName.trim().toLowerCase()
    );
    if (isDuplicate) {
      toast.error('Topic with the same name already exists. Please choose a different name.');
      return; 
    }
      const topicData = {
        multilingual_descriptions: { en: enTopicDesc, ta: taTopicDesc },
        multilingual_names: { en: enTopicName.trim(), ta: taTopicName.trim() },
      };


        dispatch(addTopics(topicData))
          .then((response) => {
            // console.log("Response:",response);
            
            const newTopicId = response?.payload?.data?.data?.data?.id;
            console.log("newTopicId:",newTopicId);
            
            if (file) {
              const formData = new FormData();
              // console.log("formData:",formData);
              
              formData.append('image', file);
          
              // Ensure the correct key is passed
              dispatch(uploadTopicImage({ topic_id: newTopicId,formData: formData }))
                .then((res) => {
                  // console.log("Image uploaded successfully:",res);
                  fetchTopics();
                })
                .catch((error) => console.error('Error uploading image:', error));
            } else {
              console.warn("No file provided for upload");
              fetchTopics();
            }
            resetFields();
            setPopup(false);
          })
          .catch((error) => console.error('Error creating topic:', error));
      }
    }

    const handleCreateNew = () => {
      setIsEditMode(false);  // Ensure it's in Create mode
      setSelectedTopic(null);  // No selected topic, to clear form
      setEnTopicName('');  // Clear the EN Topic Name
      setTaTopicName('');  // Clear the TA Topic Name
      setEnTopicDesc('');  // Clear the EN Topic Description
      setTaTopicDesc('');  // Clear the TA Topic Description
      setFile(null);  // Clear the file
      setPopup(true);  // Open the dialog
    };
    

  // const handleUpdateTopic = (topic) => {
  //   setIsEditMode(true);
  //   setSelectedTopic(topic);
  //   setEnTopicName(topic?.multilingual_names?.en || '');
  //   setTaTopicName(topic?.multilingual_names?.ta || '');
  //   setEnTopicDesc(topic?.multilingual_descriptions?.en || '');
  //   setTaTopicDesc(topic?.multilingual_descriptions?.ta || '');
  //   setFile(null);
  //   setPopup(true);
  // };

  // const handleTopicDelete = (deletedTopicId) => {
  //   setTopics((prevTopics) => prevTopics.filter((topic) => topic.id !== deletedTopicId));
  // };
  



  const handleFileChange = (e) => {
    const fileInput = e.target;
    if (fileInput && fileInput.files && fileInput.files[0]) {
      const selectedFile = fileInput.files[0];
      setFile(selectedFile);
      // console.log("File selected:", selectedFile); // Log the selected file
    } else {
      console.error("No file selected or file input is undefined.");
    }
  }; 
   // Filter out any null or invalid data
   const filteredOptions = Array.isArray(topData)
   ? topData.filter(
       (topic) => topic && topic.multilingual_names && topic.multilingual_names.en
   )
   : [];

      //SelectNews 
      useEffect(()=>{
        if (newsdata.is_international_news) {
          setNewsName(NewsNameData.find((news) => news.name === "International News"));
        } else if (newsdata.is_national_news) {
          setNewsName(NewsNameData.find((news) => news.name === "National News"));
        } else if (newsdata.is_state_news) {
          setNewsName(NewsNameData.find((news) => news.name === "State News"));
        } else if (newsdata.is_local_news) {
          setNewsName(NewsNameData.find((news) => news.name === "Local News"));
        }
       },[newsdata,NewsNameData])

       const handleNewsNameChange = (event, newValue) => {
        setNewsName(newValue);
    
        // Update newsdata state conditionally based on selected news type
        if (newValue?.name === "International News") {
          setNewsdata({
            ...newsdata,
            is_international_news: true,
            is_national_news: false,
            is_state_news: false,
            is_local_news: false,
          });
        } else if (newValue?.name === "National News") {
          setNewsdata({
            ...newsdata,
            is_international_news: false,
            is_national_news: true,
            is_state_news: false,
            is_local_news: false,
          });
        } else if (newValue?.name === "State News") {
          setNewsdata({
            ...newsdata,
            is_international_news: false,
            is_national_news: false,
            is_state_news: true,
            is_local_news: false,
          });
        } else if (newValue?.name === "Local News") {
          setNewsdata({
            ...newsdata,
            is_international_news: false,
            is_national_news: false,
            is_state_news: false,
            is_local_news: true,
          });
        }
    
        // Reset city and state names if necessary
        if (newValue?.name === "International News" || newValue?.name === "National News") {
          setCityName(null);
          setStateName(null);
        } else if (newValue?.name === "State News") {
          setCityName(null);
        } else if (newValue?.name === "Local News") {
          setStateName(null);
        }
      };

       

  // NewsCharacterCount
  const maxCharacters = 250;

  //tacharacterCount
  const taHeadlinesCount = newsdata?.content?.ta?.short_summary?.length || 0;
  const taMetaTitleCount = taMetaTitle.length || 0;
  const taMetaDescriptionCount =tametadesc.length || 0;
  const taTitleCount = taTitle.length || 0;

  //enCharcterCount
  const enHeadlinesCount = newsdata?.content?.en?.short_summary?.length || 0;
  const enMetaTitleCount = enMetaTitle.length || 0;
  const enMetaDescriptionCount =enmetadesc.length || 0;
  const enTitleCount = enTitle.length || 0;

    // Calculate word count
    const wordCountTa = content1.trim() ? content1.trim().split(/\s+/).length : 0;
    const wordCountEn = content2.trim() ? content2.trim().split(/\s+/).length : 0;

    const handleSubmit = async (e) => {
      e.preventDefault();
    
      // Validate required fields for both web and short news
      if (newsdata?.news_type === "web") {
        if (!taTitle.trim() && !enTitle.trim()) {
          if (!taTitle.trim()) setError(true);
          if (!enTitle.trim()) setError(true);
          return;
        }
      } else if (newsdata?.news_type === "short") {
        if (!newsdata?.content?.ta?.short_summary.trim() || !newsdata?.content?.en?.short_summary.trim()) {
          toast.error("Both TA and EN news content are required for short news.");
          return;
        }
      }
    
      // Prepare form data for both web and short news
      const formData = {
        category_id: categoryName?.id,
        city_id: cityname?.id,
        content: {
          ta: {
            title: taTitle,
            web_content: content1,
            news_ad_id: tanewsad?.id,
            meta_description: tametadesc,
            meta_title: taMetaTitle,
            tags: taHashtags,
            short_summary: newsdata?.content?.ta?.short_summary,
          },
          en: {
            title: enTitle,
            web_content: content2,
            news_ad_id: ennewsad?.id,
            meta_description: enmetadesc,
            meta_title: enMetaTitle,
            tags: enHashtags,
            short_summary: newsdata?.content?.en?.short_summary,
          },
        },
        is_breaking: newsdata.is_breaking,
        is_international_news: newsdata.is_international_news,
        is_national_news: newsdata.is_national_news,
        is_state_news: newsdata.is_state_news,
        is_local_news: newsdata.is_local_news,
        news_source: {
          name: newsdata?.news_source?.name,
          url: newsdata?.news_source?.url,
        },
        news_type: newsdata.news_type,
        state_id: statename?.id,
        topics: [enTopicName?.id],
      };
    
      try {
        setLoader(true);
    
        // Dispatch the createnews action
        const response = await dispatch(createnews(formData)).unwrap();
    
        // If news is created successfully, upload the image (if any)
        if (response?.data?.data?.data?.id) {
          const news_id = response.data.data.data.id;
    
          // Upload image for short news (file2 is used for short news)
          if (file2) {
            const formVata = new FormData();
            formVata.append("image", file2);
            await dispatch(uploadimage({ news_id, formVata })).unwrap();
          }
        }
    
        // Navigate to /news after successful news creation and image upload
        navigate("/news");
    
      } catch (error) {
        console.error("Error creating news:", error);
        toast.error(error?.message || "Failed to create news, please try again.");
      } finally {
        setLoader(false);
      }
    };
    
    

  return (
    <Container maxWidth="fluid" className="content" sx={{pb:`${newsdata.news_type == 'short'&& '100px'}`}}>
        <Box
           component="form"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
        >
            <Grid container spacing={2} sx={{paddingBottom: 2}}>
            <Grid
            item
            xs={8}
            sx={{
              paddingTop: 0,
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >

            <ArrowBackIcon onClick={arrowBack} sx={{ cursor: "pointer" }} />
            <Typography variant="h5"  sx={{fontWeight:'bold'}}>Add News</Typography>
          </Grid>
                <Grid item xs={4} sx={{textAlign: 'end'}}>
                <Button type='submit' size='large' sx={{marginTop: 3}} variant='contained'>Create News</Button>
                </Grid>
                <Grid item xs={6}>
                <FormControl fullWidth sx={{mb: 2}}>
        <InputLabel id="demo-simple-select-label">News Type</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={newsdata?.news_type}
          label="News Type"
          onChange={(e)=>handleValue(e, 'news_type')}
        >
          <MenuItem value='short'>short</MenuItem>
          <MenuItem value='web'>web</MenuItem>
        </Select>
      </FormControl>
                <Autocomplete
      options={catData}
      value={categoryName} 
      getOptionLabel={(option) => `${option.name}`}
      renderInput={(params) => <TextField {...params} sx={{marginBottom: 2}} label="Select Category" variant="outlined" />}
      onChange={(event, newValue) => {
        setCategoryName(newValue);
      }}
    />
<Autocomplete
  options={[{ name: "Add Option" },...topics]}
  value={topics.find((option) => option.name === enTopicName?.name) || null}
  getOptionLabel={(option) => option?.multilingual_names?.en || ""}
  filterSelectedOptions={false}
  renderInput={(params) => (
    <TextField {...params} sx={{ marginBottom: 2 }} label="Select Topics" variant="outlined" />
  )}
  onChange={(event, newValue) => {
    if (newValue?.name === "Add Option") {
      if (!topics.some((topic) => topic.name === enTopicName?.name)) {
        handleCreateNew();
      }
    } else {
      setEnTopicName(newValue);
    }
  }}
  renderOption={(props, option) =>
    option.name === "Add Option" ? (
      <li {...props} style={{ color: "blue" }}>+ Create New</li>
    ) : (
      <li {...props}>{option?.multilingual_names?.en}</li>
    )
  }
  ListboxProps={{
    onScroll: (e) => {
      const bottomReached =
        e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 10;

      if (bottomReached  && !bottomLoader && fetchedtopics?.length !== 0) {
        setPage((prevPage) => prevPage + 1);
      }
    },
  }}
/>


           {/* Popup Dialog */}
           <Dialog open={popup} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle sx={{paddingTop:3}}>{isEditMode ? 'Edit Topic' : 'Add New Topic'}</DialogTitle>
        <DialogContent>
          <Box sx={{display:'flex',justifyContent:'space-between',columnGap:'20px'}}>
            <TextField fullWidth margin='normal' label='TA Topic Name' value={taTopicName}
            onChange={(e)=> setTaTopicName(e.target.value)}
            />
            <TextField fullWidth margin='normal' label='EN Topic Name' value={enTopicName}
            onChange={(e)=> setEnTopicName(e.target.value)}
            />
            </Box>
            <Box sx={{display:'flex',justifyContent:'space-between',columnGap:'20px'}}>
            <TextField sx={{
              marginBottom: 1,
              '& .MuiInputBase-root': {height: '100px',display: 'flex', alignItems: 'start', overflow: 'hidden', position:'static',},
              '& .MuiInputBase-input': {overflow: 'auto', height: '100%'},
            }}fullWidth multiline  InputProps={{style: {overflow: "auto"}}}
             margin='normal' label='TA Topic Decription' value={taTopicDesc}
            onChange={(e)=> setTaTopicDesc(e.target.value)}/>

            <TextField sx={{
              marginBottom: 1,
              '& .MuiInputBase-root': {height: '100px',display: 'flex', alignItems: 'start', overflow: 'hidden', position:'static',},
              '& .MuiInputBase-input': {overflow: 'auto', height: '100%'},
            }}fullWidth multiline  InputProps={{style: {overflow: "auto"}}}
             margin='normal' label='EN Topic Decription' value={enTopicDesc}
            onChange={(e)=> setEnTopicDesc(e.target.value)}/>
            </Box>
             <Box sx={{marginBottom: 3, width:'60%',marginX: 'auto',}}>
                <Typography variant='body1' sx={{mb: 1}}>Image </Typography>
                <CloudUpload file={file} setFile={setFile} setLoader={setLoader} imgUrl={null}/> 
            </Box>
        </DialogContent>
        <DialogActions sx={{marginBottom:3,marginRight:3}}>
            <Button onClick={handleClose}>Cancel</Button>
            <Button variant='contained' onClick={handleAddNewTopic}>
            {isEditMode ? 'Update' : 'Create'}
            </Button>
        </DialogActions>
    </Dialog>

      {newsdata?.news_type === "short" ?(
          <Autocomplete
          options={NewsNameData}
          value={newsName} 
          getOptionLabel={(option) => `${option.name}`}
          renderInput={(params) => <TextField {...params} sx={{marginBottom: 2}} label="Select News" variant="outlined" />}
          onChange={handleNewsNameChange}

          />
    ):null}

                    <Autocomplete
      options={cityData}
      value={cityname} 
      getOptionLabel={(option) => `${option.name}`}
      renderInput={(params) => <TextField {...params} sx={{marginBottom: 2}} label="Select City" variant="outlined" />}
      onChange={(event, newValue) => {
        setCityName(newValue);
      }}
      disabled={isCityDisabaled}
    />
                        <Autocomplete
      options={stateData}
      value={statename} 
      getOptionLabel={(option) => `${option.name}`}
      renderInput={(params) => <TextField {...params} sx={{marginBottom: 2}} label="Select State" variant="outlined" />}
      onChange={(event, newValue) => {
        setStateName(newValue);
      }}
      disabled={isStateDisabled}
    />
                </Grid>
                <Grid item xs={3}>
                {newsdata?.news_type !== "short" ? 
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox
            checked={newsdata.is_breaking}
            onChange={handleCheckboxChange}
            name="is_breaking"
          />
        }
        label="Breaking News"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={newsdata.is_international_news}
            onChange={handleCheckboxChange}
            name="is_international_news"
          />
        }
        label="International News"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={newsdata.is_national_news}
            onChange={handleCheckboxChange}
            name="is_national_news"
          />
        }
        label="National News"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={newsdata.is_state_news}
            onChange={handleCheckboxChange}
            name="is_state_news"
          />
        }
        label="State News"
      />
    </FormGroup>
    : null}
                </Grid>
                <Grid item xs={3}>
                <TextField sx={{marginBottom: 2}} fullWidth id="outlined-basic" label="News Source Name" variant="outlined" InputLabelProps={{ required: true }}
                value={newsdata?.news_source?.name}
                onChange={(e)=>handleValue(e, 'nsname')}
                />
                <TextField sx={{marginBottom: 2}} fullWidth id="outlined-basic" label="News Source URL" variant="outlined" InputLabelProps={{ required: true }}
                value={newsdata?.news_source?.url}
                onChange={(e)=>handleValue(e, 'nsurl')}
                />
                {newsdata?.news_type === 'short' ? 
                                <Box sx={{marginBottom: 3}}>
                <Typography variant='body1' sx={{mb: 1}}>Image *</Typography>
                <CloudUpload file={file2} setFile={setFile2} setLoader={setLoader} imgUrl={null}/>
                </Box>
                : null}
                </Grid>
                <Grid item xs={6}>
                {newsdata?.news_type !== "short" ? 
                <div>
                <IconButton sx={{ fontSize: '0.9rem', color: 'primary.main', marginBottom:1 }}  onClick={()=>handleTranslateWebEnTitle(newsid)}>
                  TA<ArrowForwardIcon sx={{ fontSize: '0.875rem', marginX: 0.5}} />EN
                 </IconButton>
                <TextField sx={{marginBottom: 2}} fullWidth id="outlined-basic" label="TA Meta Title" variant="outlined" InputLabelProps={{ required: true }}
                value={taMetaTitle}
                onFocus={() => setIsFocussed('tametatitle')}
                onBlur={() => setIsFocussed(null)}
                onChange={(e)=>handleValue(e, 'tametatitle')}
                />
                {isFocussed ==='tametatitle' && taMetaTitleCount === 0 && (
                  <Typography variant="body2" color= 'error' sx={{ marginBottom: 1,marginLeft:1 }}>
                 Maximum {maxCharacters} characters
               </Typography>
              )}
              {isFocussed ==='tametatitle' && taMetaTitleCount > 0 && (
                <Typography variant="body2" color="textSecondary" sx={{ marginBottom: 1,marginLeft:1 }}>
                   {taMetaTitleCount} characters
                 </Typography>
              )}
              </div>
                : null}
                {newsdata?.news_type !== "short" ? 
                <div>
                  <IconButton sx={{ fontSize: '0.9rem', color: 'primary.main', marginBottom:1 }}  onClick={()=>handleTranslateWebEnDesc(newsid)}>
                  TA<ArrowForwardIcon sx={{ fontSize: '0.875rem', marginX: 0.5}} />EN
                 </IconButton>
                <TextField sx={{marginBottom: 2}} fullWidth id="outlined-basic" label="TA Meta Description" variant="outlined" InputLabelProps={{ required: true }}
                 value={tametadesc}
                 onFocus={() => setIsFocussed('tametadescription')}
                 onBlur={() => setIsFocussed(null)}
                onChange={(e)=>handleValue(e, 'tametadescription')} 
                />
                {isFocussed === 'tametadescription' && taMetaDescriptionCount === 0 && (
                <Typography variant="body2" color= 'error' sx={{ marginBottom: 1,marginLeft:1 }}>
                 Maximum {maxCharacters} characters
               </Typography>
              )}
              {isFocussed === 'tametadescription' && taMetaDescriptionCount > 0 && (
                <Typography variant="body2" color="textSecondary" sx={{ marginBottom: 1,marginLeft:1 }}>
                   {taMetaDescriptionCount} characters
                 </Typography>
              )}
                </div>
                : null}
      
  

                {newsdata?.news_type == "short" ? 
                                <Autocomplete
      options={adData}
      value={tanewsad} 
      getOptionLabel={(option) => `${option.title}`}
      renderInput={(params) => <TextField {...params} sx={{marginBottom: 2}} label="Select TA News Ad" variant="outlined" />}
      onChange={(event, newValue) => {
        settaNewsAd(newValue);
      }}
    />
    : null}

     <div>
           <TextField
             label="TA Tags"
             variant="outlined"
             fullWidth
             value={inputValueTa}
             InputLabelProps={{ required: true }}
             onChange={handleInputChangeTa}
             onKeyDown={handleInputKeyDownTa}
             placeholder="Press Enter to add tags"
           />
           <div style={{marginTop: 10,marginBottom:10}}>
             {taHashtags.map((tag, index) => (
               <Chip
                 key={index}
                 label={`#${tag}`}
                 onDelete={() => handleDeleteChipTa(index)}
                 sx={{marginRight: 1}}
               />
             ))}
           </div>
         </div>
        {newsdata?.news_type !== "short" ? (
        <>
                <div>
                <IconButton sx={{ fontSize: '0.9rem', color: 'primary.main', }}  onClick={()=>handleTranslateWebEnTit(newsid)}>
                  TA<ArrowForwardIcon sx={{ fontSize: '0.875rem', marginX: 0.5}} />EN
                 </IconButton>
                  <TextField sx={{marginBottom: 2}} fullWidth id="outlined-basic" label="TA Title" variant="outlined" InputLabelProps={{ required: true }}
                value={taTitle}
                onFocus={() => setIsFocussed('tntitle')}
                onBlur={() => {
                  setIsFocussed(null);
                  if (!taTitle.trim()) setError(true);
                }}
                onChange={(e) => {
                  handleValue(e, "tntitle");
                  setError(false); 
                }}
                error={error && !taTitle.trim()} 
                helperText={error && !taTitle.trim() ? 'TA Title is required' : ''}
                />
              {isFocussed ==='tntitle' && taTitleCount === 0 && (
                  <Typography variant="body2" color= 'error' sx={{ marginBottom: 1,marginLeft:1 }}>
                 Maximum {maxCharacters} characters
               </Typography>
              )}
              {isFocussed ==='tntitle' && taTitleCount > 0 && (
                <Typography variant="body2" color="textSecondary" sx={{ marginBottom: 1,marginLeft:1 }}>
                   {taTitleCount} characters
                 </Typography>
              )}
              </div>
                {/* <TextField sx={{marginBottom: 2}} fullWidth id="outlined-basic" label="TA Headlines" variant="outlined" InputLabelProps={{ required: true }}
                 value={newsdata?.content?.ta?.headlines}
                onChange={(e)=>handleValue(e, 'tnheadlines')} 
                /> */}
                </>
        ):(
          <>
          {/* <TextField sx={{marginBottom: 2}} fullWidth id="outlined-basic" label="TA Title" variant="outlined" InputLabelProps={{ required: true }}
          value={newsdata?.content?.ta?.title}
          onChange={(e)=>handleValue(e, 'tntitle')}
          /> */}
          <div>
          <IconButton sx={{ fontSize: '0.9rem', color: 'primary.main' ,mb:1}} onClick={()=>handleTranslateShortEn(newsid)}>
            TA<ArrowForwardIcon sx={{ fontSize: '0.875rem', marginX: 0.5}} />EN
          </IconButton>
          <TextField
           sx={{
             marginBottom: 1,
             '& .MuiInputBase-root': {height: '100px',display: 'flex', alignItems: 'start', overflow: 'hidden', position:'static',},
             '& .MuiInputBase-input': {overflow: 'auto', height: '100%'},
           }}
           fullWidth id="outlined-basic" label="TA News" variant="outlined"
           InputLabelProps={{ required: true}}
           InputProps={{
             style: {overflow: "auto"}
           }}
           multiline
           value={newsdata?.content?.ta?.short_summary}
           onFocus={() => setIsFocussed('tnshort_summary')}
           onBlur={() => setIsFocussed(null)}
           onChange={(e)=>handleValue(e, 'tnshort_summary')} 
           />
           {isFocussed === 'tnshort_summary' && taHeadlinesCount === 0 && (
             <Typography variant="body2" color= 'error' sx={{ marginBottom: 1,marginLeft:1 }}>
            Maximum {maxCharacters} characters
          </Typography>
         )}
         {isFocussed === 'tnshort_summary' && taHeadlinesCount > 0 && (
           <Typography variant="body2" color="textSecondary" sx={{ marginBottom: 1,marginLeft:1 }}>
              {taHeadlinesCount} characters
            </Typography>
         )}
         </div>
          </>
        )
        }
                {/* <TextField sx={{marginBottom: 2}} multiline rows={2} fullWidth id="outlined-basic" label="Short Summary" variant="outlined" InputLabelProps={{ required: true }}
                  value={newsdata?.content?.ta?.short_summary}
                onChange={(e)=>handleValue(e, 'tnshort_summary')}
                />
                <Box sx={{marginBottom: 3}}>
                <Typography variant='body1' sx={{mb: 1}}>Summary Image *</Typography>
                <CloudUpload file={file1} setFile={setFile1} setLoader={setLoader}/>
                </Box> */}
                {newsdata?.news_type !== "short" ? 
                <>
                <Box sx={{display:'flex',justifyContent:'space-between',marginRight:2}}>
                  <div>
                <Typography variant='body1' sx={{mb: 1}}>TA News * 
                <IconButton sx={{ fontSize: '0.9rem', color: 'primary.main' }} onClick={()=>handleTranslateWebEn(newsid)}>
                  TA<ArrowForwardIcon sx={{ fontSize: '0.875rem', marginX: 0.5}} />EN
                 </IconButton>
                </Typography>
                </div>
                <Typography variant='body1'  color="textSecondary" sx={{mb: 1}}> Word Count: {wordCountTa}</Typography>
                </Box>
                <Box sx={{height:'50%'}}>
                <Editor content={content1} setContent={setContent1} placeholder={'Write something...'} />
                </Box>
                </>
                : null}
                </Grid>
                <Grid item xs={6}>
                {newsdata?.news_type !== "short" ? 
                <Box sx={{marginBottom: 3,marginTop:-14.7}}>
                <Typography variant='body1' sx={{mb: 1}}>Image *</Typography>
                <CloudUpload file={file2} setFile={setFile2} setLoader={setLoader}/>
                </Box>
                : null}

               {newsdata?.news_type !== "short" ?
               <div> 
                <IconButton sx={{ fontSize: '0.9rem', color: 'primary.main', marginBottom:1 }}  onClick={()=>handleTranslateWebTaTitle(newsid)}>
                  EN<ArrowForwardIcon sx={{ fontSize: '0.875rem', marginX: 0.5}} />TA
                 </IconButton>
                <TextField sx={{marginBottom: 2}} fullWidth id="outlined-basic" label="EN Meta Title" variant="outlined" InputLabelProps={{ required: true }}
                value={enMetaTitle}
                onFocus={() => setIsFocussed('enmetatitle')}
                onBlur={() => setIsFocussed(null)}
                onChange={(e)=>handleValue(e, 'enmetatitle')}
                />
                {isFocussed ==='enmetatitle' && enMetaTitleCount === 0 && (
                  <Typography variant="body2" color= 'error' sx={{ marginBottom: 1,marginLeft:1 }}>
                 Maximum {maxCharacters} characters
                 </Typography>
                )}
              {isFocussed ==='enmetatitle' && enMetaTitleCount > 0 && (
                <Typography variant="body2" color="textSecondary" sx={{ marginBottom: 1,marginLeft:1 }}>
                   {enMetaTitleCount} characters
                 </Typography>
              )}
                </div>
                : null}
                {newsdata?.news_type !== "short" ? 
                <div>
                  <IconButton sx={{ fontSize: '0.9rem', color: 'primary.main', marginBottom:1 }}  onClick={()=>handleTranslateWebTaDesc(newsid)}>
                  EN<ArrowForwardIcon sx={{ fontSize: '0.875rem', marginX: 0.5}} />TA
                 </IconButton>
                <TextField sx={{marginBottom: 2}} fullWidth id="outlined-basic" label="EN Meta Description" variant="outlined" InputLabelProps={{ required: true }}
                 value={enmetadesc}
                 onFocus={() => setIsFocussed('enmetadescription')}
                 onBlur={() => setIsFocussed(null)}
                onChange={(e)=>handleValue(e, 'enmetadescription')} 
                />
                {isFocussed ==='enmetadescription' && enMetaDescriptionCount === 0 && (
                  <Typography variant="body2" color= 'error' sx={{ marginBottom: 1,marginLeft:1 }}>
                 Maximum {maxCharacters} characters
               </Typography>
              )}
              {isFocussed ==='enmetadescription' && enMetaDescriptionCount > 0 && (
                <Typography variant="body2" color="textSecondary" sx={{ marginBottom: 1,marginLeft:1 }}>
                   {enMetaDescriptionCount} characters
                 </Typography>
              )}
                </div>
                : null}
            
  
  
    {newsdata?.news_type == "short" ? 
                <Autocomplete
      options={adData}
      value={ennewsad} 
      getOptionLabel={(option) => `${option.title}`}
      renderInput={(params) => <TextField {...params} sx={{marginBottom: 2}} label="Select EN News Ad" variant="outlined" />}
      onChange={(event, newValue) => {
        setenNewsAd(newValue);
      }}
    />
    : null}

      <div>
            <TextField
              label="EN Tags"
              variant="outlined"
              fullWidth
              value={inputValueEn}
              InputLabelProps={{ required: true }}
              onChange={handleInputChangeEn}
              onKeyDown={handleInputKeyDownEn}
              placeholder="Press Enter to add tags"
            />
            <div style={{marginTop: 10,marginBottom:10}}>
              {enHashtags.map((tag, index) => (
                <Chip
                  key={index}
                  label={`#${tag}`}
                  onDelete={() => handleDeleteChipEn(index)}
                  sx={{marginRight: 1}}
                />
              ))}
            </div>
          </div>
              {newsdata?.news_type !== "short" ? (
                <>
                <div>
                <IconButton sx={{ fontSize: '0.9rem', color: 'primary.main', marginBottom:1 }}  onClick={()=>handleTranslateWebTaTit(121)}>
                  TA<ArrowForwardIcon sx={{ fontSize: '0.875rem', marginX: 0.5}} />EN
                 </IconButton>
                <TextField sx={{marginBottom: 2}} fullWidth id="outlined-basic" label="EN Title" variant="outlined" InputLabelProps={{ required: true }}
                value={enTitle}
                onFocus={() => setIsFocussed('entitle')}
                onBlur={() => {
                  setIsFocussed(null);
                  if (!enTitle.trim()) setError(true);
                }}
                onChange={(e) => {
                  handleValue(e, "entitle");
                  if (error) setError(false);
                }}
                error={error && !enTitle.trim()} 
                helperText={error && !enTitle.trim() ? "EN Title is required" : ""}
                />
              {isFocussed ==='entitle' && enTitleCount === 0 && (
                  <Typography variant="body2" color= 'error' sx={{ marginBottom: 1,marginLeft:1 }}>
                 Maximum {maxCharacters} characters
               </Typography>
              )}
              {isFocussed ==='entitle' && enTitleCount > 0 && (
                <Typography variant="body2" color="textSecondary" sx={{ marginBottom: 1,marginLeft:1 }}>
                   {enTitleCount} characters
                 </Typography>
              )}
                </div>
                {/* <TextField sx={{marginBottom: 2}} fullWidth id="outlined-basic" label="EN Headlines" variant="outlined" InputLabelProps={{ required: true }}
                 value={newsdata?.content?.en?.headlines}
                onChange={(e)=>handleValue(e, 'enheadlines')} 
                /> */}
                </>
              ):(
                <>
                {/* <TextField sx={{marginBottom: 2}} fullWidth id="outlined-basic" label="EN Title" variant="outlined" InputLabelProps={{ required: true }}
                value={newsdata?.content?.en?.title}
                onChange={(e)=>handleValue(e, 'entitle')}
                /> */}
                <div>
                <IconButton sx={{ fontSize: '0.9rem', color: 'primary.main',mb:1 }}  onClick={()=>handleTranslateShortTa(newsid)}>
                   EN<ArrowForwardIcon sx={{ fontSize: '0.875rem', marginX: 0.5}} />TA
                  </IconButton>
                <TextField
                 sx={{
                   marginBottom: 1,
                   '& .MuiInputBase-root': {height: '100px',display: 'flex', alignItems: 'start', overflow: 'hidden', position:'static',},
                   '& .MuiInputBase-input': {overflow: 'auto', height: '100%'},
                 }}
                 fullWidth id="outlined-basic" label="EN News" variant="outlined"
                 InputLabelProps={{ required: true}}
                 InputProps={{
                   style: {overflow: "auto"}
                 }}
                 multiline
                 value={newsdata?.content?.en?.short_summary}
                 onFocus={() => setIsFocussed('enshort_summary')}
                 onBlur={() => setIsFocussed(null)}
                 onChange={(e)=>handleValue(e, 'enshort_summary')} 
                 />
                  {isFocussed === 'enshort_summary' && enHeadlinesCount === 0 && (
                    <Typography variant="body2" color= 'error' sx={{ marginBottom: 1,marginLeft:1 }}>
                      Maximum {maxCharacters} characters
                    </Typography>
                  )}
                  {isFocussed === 'enshort_summary' && enHeadlinesCount > 0 && (
                    <Typography variant="body2" color="textSecondary" sx={{ marginBottom: 1,marginLeft:1 }}>
                      {enHeadlinesCount} characters
                    </Typography>
                  )}
                 </div>
                </>
              )}
                {/* <TextField sx={{marginBottom: 2}} multiline rows={2} fullWidth id="outlined-basic" label="Short Summary" variant="outlined" InputLabelProps={{ required: true }}
                  value={newsdata?.content?.en?.short_summary}
                onChange={(e)=>handleValue(e, 'enshort_summary')}
                /> */}
                {newsdata?.news_type !== "short" ? 
                  <>
                 <Box sx={{display:'flex',justifyContent:'space-between',marginRight:2}}>
                <div>
                <Typography variant='body1' sx={{mb: 1}}>EN News * 
                <IconButton sx={{ fontSize: '0.9rem', color: 'primary.main' }}  onClick={()=>handleTranslateWebTa(newsid)}>
                  EN<ArrowForwardIcon sx={{ fontSize: '0.875rem', marginX: 0.5}} />TA
                 </IconButton>
                </Typography>
                </div>
                <Typography variant='body1'  color="textSecondary" sx={{mb: 1}}> Word Count: {wordCountEn}</Typography>
                </Box>
                <Box sx={{height:'50%'}}>
                <Editor content={content2} setContent={setContent2} placeholder={'Write something...'} />
                </Box>
                </>
                : null}
                </Grid>
            </Grid>
       </Box>
    </Container>
  );
}
