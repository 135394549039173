import React from 'react';
import { Badge, Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

// Custom Badge styles
const StyledBadge = styled(Badge)(({ bgcolor }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: bgcolor,
        color: 'white',
        fontSize: '1rem',
        width: 'auto', // Adjust width to fit larger numbers
        height: '34px',
        padding: '0 8px', // Add padding to accommodate larger numbers
        borderRadius: '50%',
        minWidth: '34px', // Ensure minimum width
    },
}));

const StatusDisplay = ({ status }) => {
    // Define color mapping based on the status
    const colorMap = {
        approved: '#f7b235',
        pending: '#ffe600',
        rejected: '#d93025',
    };

    return (
        <Box display="flex" alignItems="end" justifyContent="end" gap={4} p={2} >
            {status.map((item) => (
                <Box key={item.status} display="flex" alignItems="center">
                    <StyledBadge
                        badgeContent={item.total_count}
                        bgcolor={colorMap[item.status]}
                        max={9999} // Allows displaying large numbers without "99+"
                    />
                    <Typography ml={4} textTransform="capitalize">
                        {item.status}
                    </Typography>
                </Box>
            ))}
        </Box>
    );
};

export default StatusDisplay;
