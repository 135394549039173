import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;

const getNews = (page) => {
  return axios.get(API_URL + 'core-svc/api/v1/news/', { 
    params: page,
    headers: authHeader() });
};

const getStoryNews = (page) => {
  return axios.get(API_URL + 'news-svc/api/v1/news/', { 
    params: page,
    headers: authHeader() });
};

const createNews = (formData) => {
  return axios.post(API_URL + 'core-svc/api/v1/news/', {...formData}, { 
    headers: authHeader() });
};

const createNewsStories = (formData) => {
  return axios.post(API_URL + 'core-svc/api/v1/news-stories/', {...formData}, { 
    headers: authHeader() });
};

//newstories for search
// const searchNewsStories = (formData) => {
//   return axios.post(API_URL + 'core-svc/api/v1/news-stories/search', {...formData}, { 
//     headers: authHeader() });
// };

const uploadImage = (news_id, formVata) => {
  //console.log(news_id, formVata);
  return axios.post(API_URL + `core-svc/api/v1/news/${news_id}/image/en`, formVata,{
    headers: {
      ...authHeader(), 
      'Content-Type': 'multipart/form-data'
    }});
};
//uploadStoryImage
const uploadStoryImage = (news_story_id, formVata) => {
  //console.log(news_story_id, formVata);
  return axios.post(API_URL + `core-svc/api/v1/news-stories/news/${news_story_id}/image`, formVata,{
    headers: {
      ...authHeader(), 
      'Content-Type': 'multipart/form-data'
    }});
};

const getNewsbyid = async (news_id) => {
  const response = await axios.get(API_URL + `core-svc/api/v1/news/${news_id}`, { headers: authHeader() });
  return response.data;
};

const getStoriesbyid = async (news_id) => {
  const response = await axios.get(API_URL + `core-svc/api/v1/news-stories/${news_id}`, { headers: authHeader() });
  return response.data;
};

const updateNews = async (news_id, formData) => {
  const response = await axios.put(API_URL + `core-svc/api/v1/news/${news_id}`, {...formData}, { headers: authHeader() });
  return response.data;
};

//Update Newsstories
const updateStories = async (id, formData) => {
  const response = await axios.put(API_URL + `core-svc/api/v1/news-stories/${id}`, {...formData}, { headers: authHeader() });
  return response.data;
};


const updateActions = async (news_id, val) => {
  const response = await axios.put(API_URL + `core-svc/api/v1/news/${news_id}/action/${val}?language=en`, null, { headers: authHeader()});
  return response.data;
};

const updatePublish = async (news_id) => {
  const response = await axios.get(API_URL + `core-svc/api/v1/news/${news_id}/publish?language=en`, { headers: authHeader()});
  return response.data;
};

const deleteNews = async (news_id) => {
  const response = await axios.delete(API_URL + `core-svc/api/v1/news/${news_id}`, { headers: authHeader() });
  return response.data;
};

const getNewsStories = (formData) => {
  return axios.post(API_URL + 'core-svc/api/v1/news-stories/search', {...formData}, { headers: {
    ...authHeader(),
    'accept-language': 'en'
}});
};

const getCategories = (formData) => {
  return axios.post(API_URL + 'core-svc/api/v1/categories/search', {...formData}, { headers: {
    ...authHeader(),
    'accept-language': 'en'
  }});
};

const getTopics = (formData) => {
  return axios.post(API_URL + 'core-svc/api/v1/news-topics/search', {...formData}, { headers: {
    ...authHeader(),
    'accept-language': 'en'
  }});
};

const getNewsad = (formData) => {
  return axios.post(API_URL + 'core-svc/api/v1/news-ads/search', {...formData}, { headers: {
    ...authHeader(),
    'accept-language': 'en'
  }});
};
//get Comments 
const getComments = (formData) => {
  return axios.get(API_URL + 'core-svc/api/v1/comments/', {...formData}, { headers: {
    ...authHeader(),
    'accept-language': 'en'
  }});
};


//Delete Comments in comments
const deleteComments = async (comment_id)=>{
  const response=await axios.delete(API_URL + `core-svc/api/v1/comments/${comment_id}`, { headers: authHeader() })
  return response.data
}


//Delete Stories in comments
const deleteStories = async (stories_id)=>{
  const response=await axios.delete(API_URL + `core-svc/api/v1/news-stories/${stories_id}`, { headers: authHeader() })
  return response.data
}

//card endpoint
const cardEndpoint = async (page,news_id)=>{
  const response=await axios.get(API_URL + `/core-svc/api/v1/news/${news_id}/card`,{headers:authHeader()})
  return response.data
}
const getAdminStates = async ()=>{
  const response=await axios.get(API_URL + `core-svc/api/v1/news/admin/stats`,{headers:authHeader()})
  return response.data
}


const getCity = () => {
  return axios.get(API_URL + 'core-svc/api/v1/cities');
};

const getStates = () => {
  return axios.get(API_URL + 'core-svc/api/v1/states');
};

const newsService = {
  getNews,
  getStoryNews,
  createNews,
  createNewsStories,
  uploadImage,
  getNewsbyid,
  getStoriesbyid,
  updateNews,
  updateActions,
  updatePublish,
  deleteNews,
  getNewsStories,
  getCategories,
  getTopics,
  getNewsad,
  getComments,
  deleteComments,
  cardEndpoint,
  getCity,
  getStates,
  uploadStoryImage,
  // searchNewsStories,
  getAdminStates,
  updateStories,
  deleteStories
};

export default newsService