import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { styled, alpha } from '@mui/material/styles';
import { Button, Container, List, ListItem, ListItemText, Paper, Typography, Autocomplete, Box, TextField, Pagination } from '@mui/material';
import BasicTabs from '../../components/tabs/Tabs1';
import { getnews, getAdminStats, upnews, getcategories, getcity } from '../../slices/news';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import newsService from '../../services/news.service';
import MediaCard from '../../components/card/Card';
import { setStatus } from '../../slices/Status';

import dayjs from 'dayjs';
import Loader from '../../components/loader/Loader';
import loaderFile from "../../assets/gif/loader.gif";

const Search = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 100,
  right: 150,
  borderRadius: theme.shape.borderRadius,
  border: '1px solid #000',
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: '#000',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1.5, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '25ch',
    },
  },
}));

const NoNewsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '300px',
  padding: theme.spacing(3),
}));

const News = ({ setLoader }) => {
  const [results, setResults] = useState([]);
  const [filteredSearchResults, setFilteredSearchResults] = useState([]);
  const [page, setPage] = useState(1);
  const [newstype, setNewstype] = useState("short");
  const status = useSelector((state) => state.status);
  const [query, setQuery] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const { categories } = useSelector((state) => state.news);
  const { cities } = useSelector((state) => state.news);
  const [category, setCategory] = useState(null);
  const [city, setCity] = useState(null);  
  
  const [cityData, setCityData] = useState(null);  
  const [noNewsFound, setNoNewsFound] = useState(false);
  const [bottomLoader, setbottomLoader] = useState(false);
  const dispatch = useDispatch();
  const [filteredNews, setFilteredNews] = useState(null);
  const [openPaper, setOpenPaper] = useState(false);
  const [focused, setFocused] = useState(false);  

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [newsSourceName, setNewsSourceName] = useState('');
  const [stats, setstats] = useState();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  // Get values from URL params (defaulting to false if not present)
  const isStateNews = searchParams.get("is_state_news") === "true";
  const isNationalNews = searchParams.get("is_national_news") === "true";
  const isInternationalNews = searchParams.get("is_international_news") === "true";

  // State for checkboxes
  const [filters, setFilters] = React.useState({
    isStateNews,
    isNationalNews,
    isInternationalNews,
  });

  // Update state if URL params change (e.g., on page load)
  useEffect(() => {
    setFilters({ isStateNews, isNationalNews, isInternationalNews });
  }, [isStateNews, isNationalNews, isInternationalNews]);

  useEffect(() => {
    setFilters({
      isStateNews: getBooleanParam("is_state_news"),
      isNationalNews: getBooleanParam("is_national_news"),
      isInternationalNews: getBooleanParam("is_international_news"),
    });
  }, [searchParams]); 

  const getBooleanParam = (param) => searchParams.get(param) === "true";

  useEffect(() => {
    setCategory(categories.find((c) => c.name === searchParams.get("category")) || null);
    setQuery(searchParams.get("category") || '');
    
    setCity(cities.find((c) => c.name === searchParams.get("city")) || null);
    if (searchParams.get("city")) {
      setQuery(searchParams.get("city"));
    }

    // Parse date params if they exist
    const startDateParam = searchParams.get("start_date");
    const endDateParam = searchParams.get("end_date");
    
    if (startDateParam) {
      setStartDate(dayjs(startDateParam));
    }
    
    if (endDateParam) {
      setEndDate(dayjs(endDateParam));
    }
    
    // Parse source param
    const sourceParam = searchParams.get("source");
    if (sourceParam) {
      setNewsSourceName(sourceParam);
    }
  }, [categories, cities, searchParams]);

  const paperRef = useRef(null); // Create a ref for the Paper component
  const searchInputRef = useRef(null); // Create a ref for the search input

  // Function to handle outside click
  const handleClickOutside = (event) => {
    if (paperRef.current && !paperRef.current.contains(event.target) && 
        searchInputRef.current && !searchInputRef.current.contains(event.target)) {
      setOpenPaper(false); // Close the Paper
    }
  };

  useEffect(() => {
    // Add event listener for clicks
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup event listener on unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setLoader(true);
    dispatch(getcity())
      .then((data) => {
        const updata = data.payload.data.data.data;
        setCityData(updata);
      })
      .catch(() => {
        setLoader(false);
      });
      
    dispatch(getAdminStats())
      .then((data) => {
        const updata = data.payload.data.data;
        setstats(updata);
      })
      .catch(() => {
        setLoader(false);
      });
    setLoader(false);
  }, []);

  const fetchData = async (params) => {
    try {
      setbottomLoader(true);
      setNoNewsFound(false);
      
      // Fetch the news data
      const data = await dispatch(getnews(params));
      
      const fetchedNews = data?.payload?.data?.data?.data || [];
      
      // Update filtered news state
      setFilteredNews(fetchedNews);

      // Set no news found flag if there's no data
      if (fetchedNews.length === 0) {
        setNoNewsFound(true);
      } else {
        setNoNewsFound(false);
      }

      // Handle pagination: if it's the first page, set results directly; otherwise, append more data
      if (page === 1) {
        setResults(fetchedNews);
        // Update filtered search results when new data is fetched
        if (searchTerm) {
          const filtered = fetchedNews.filter(item => 
            item?.content?.en?.meta_title?.toLowerCase().includes(searchTerm.toLowerCase())
          );
          setFilteredSearchResults(filtered);
        } else {
          setFilteredSearchResults(fetchedNews);
        }
      } else {
        setResults((prevResults) => {
          const newResults = [...prevResults, ...fetchedNews];
          // Update filtered search results when new data is fetched
          if (searchTerm) {
            const filtered = newResults.filter(item => 
              item?.content?.en?.meta_title?.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setFilteredSearchResults(filtered);
          } else {
            setFilteredSearchResults(newResults);
          }
          return newResults;
        });
      }

    } catch (error) {
      console.error("Error fetching data:", error);
      setNoNewsFound(true);
    } finally {
      setbottomLoader(false);
      setLoader(false);
    }
  };

  useEffect(() => {
    // Make sure we have the category and city data before sending the request
    const categoryId = category?.id || 
      (searchParams.get("category") && categories.length > 0 ? 
       categories.find(c => c.name === searchParams.get("category"))?.id : 
       null);
    
    const cityId = city?.id || 
      (searchParams.get("city") && cities.length > 0 ? 
       cities.find(c => c.name === searchParams.get("city"))?.id : 
       null);
    
    // Update search params with date values if they exist
    const newParams = new URLSearchParams(searchParams);
    if (startDate) {
      newParams.set("start_date", dayjs(startDate).format('YYYY-MM-DD'));
    } else {
      newParams.delete("start_date");
    }
    
    if (endDate) {
      newParams.set("end_date", dayjs(endDate).format('YYYY-MM-DD'));
    } else {
      newParams.delete("end_date");
    }
    
    if (newParams.toString() !== searchParams.toString()) {
      setSearchParams(newParams);
    }
    
    // Prepare API parameters with correct naming
    const params = {
      status: status,
      news_type: newstype,
      page: page,
      news_source: searchParams.get("source") || newsSourceName || '',
      limit: 12,
      category_id: categoryId,
      city_id: cityId,
      from_date: startDate ? dayjs(startDate).format('YYYY-MM-DD') : null,
      to_date: endDate ? dayjs(endDate).format('YYYY-MM-DD') : null,
      is_state_news: filters.isStateNews || null,
      is_national_news: filters.isNationalNews || null,
      is_international_news: filters.isInternationalNews || null,
      query: searchTerm || query || '' // Add search term to query parameter
    };
    
    // Fetch data with the prepared parameters
    fetchData(params);

  }, [newstype, query, page, status, category, city, startDate, endDate, newsSourceName, filters, categories, cities, searchParams, searchTerm]);

  const updateNews = (event, value) => {
    setPage(value);
  };

  const handleInputChange = (e) => {
    const newSearchTerm = e.target.value;
    setSearchTerm(newSearchTerm);
    setPage(1); // Reset to page 1 when search changes
    
    // Filter results based on search term
    if (!newSearchTerm) {
      setFilteredSearchResults(results);
    } else {
      const filtered = results.filter(item => 
        item?.content?.en?.meta_title?.toLowerCase().includes(newSearchTerm.toLowerCase())
      );
      setFilteredSearchResults(filtered);
    }
    
    // Set search params to reflect the current search term
    const newParams = new URLSearchParams(searchParams);
    if (newSearchTerm) {
      newParams.set("search", newSearchTerm);
    } else {
      newParams.delete("search");
    }
    
    if (newParams.toString() !== searchParams.toString()) {
      setSearchParams(newParams);
    }
  };

  const handleTabChange = (newTabType) => {
    console.log("Tab changed to:", newTabType);
    setNewstype(newTabType);
  };

  useEffect(() => {
    // Reset the page to 1 whenever newstype changes
    setPage(1);
  }, [newstype, status, query, searchTerm]); 

  const handleFocus = () => {
    setFocused(true);
    // Show paper when the input is focused, regardless of whether there's content
    setOpenPaper(true);
  };

  const handleBlur = () => {
    setFocused(false);
    // Don't close the paper on blur to allow clicking on search results
    // Paper will close when clicking outside due to the click outside handler
  };

  const handleScroll = (e) => {
    const totalItems = stats ? stats[newstype][status] : 0;
    const totalPages = Math.ceil(totalItems / 12);

    const bottomReached = e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 10;
  
    // Check if the user has scrolled close to the bottom and if there are more items
    if (bottomReached) {
      if (page < totalPages && filteredNews?.length !== 0) {
        setPage((prevPage) => prevPage + 1);
      } 
    }
  };

  const handleDateChange = (type, newValue) => {
    if (type === 'start') {
      setStartDate(newValue);
    } else {
      setEndDate(newValue);
    }
    setPage(1); // Reset to page 1 when date changes
  };

  return (
    <Container className="content" maxWidth="fluid" onScroll={handleScroll} style={{overflowY:'auto', maxHeight:'87vh', position:'relative'}}>
      <BasicTabs 
        setQuery={setQuery}
        setPage={setPage}
        setLoader={setLoader} 
        status={status} 
        setNewstype={handleTabChange} 
        newsData={results}
        categories={categories}
        category={category}
        setCategory={setCategory}
        cityData={cityData}
        city={city}
        setCity={setCity}  
        startDate={startDate}
        setStartDate={(val) => handleDateChange('start', val)}
        endDate={endDate}
        setEndDate={(val) => handleDateChange('end', val)}
        newstype={newstype}
        newsSourceName={newsSourceName}
        setNewsSourceName={setNewsSourceName}
        bottomLoader={bottomLoader}
        filters={filters} 
        setFilters={setFilters}
      />

      <Link to="/news/addnews">
        <Button sx={{ position: 'absolute', top: 70, right: 30 }} variant="contained">
          New News
        </Button>
      </Link>

      {/* Search Input */}
      <Search sx={{ position: 'absolute', top: 70, right: 160 }}>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase
          ref={searchInputRef}
          placeholder="Search…"
          value={searchTerm}
          onChange={handleInputChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onClick={handleFocus} 
          inputProps={{ 'aria-label': 'search' }}
        />
      </Search>
      
      {/* No News Message */}
      {noNewsFound && !bottomLoader && (
        <NoNewsContainer>
          <Typography variant="h5" color="textSecondary" gutterBottom>
            No News Found
          </Typography>
          <Typography variant="body1" color="textSecondary">
            Try adjusting your filters or search criteria
          </Typography>
        </NoNewsContainer>
      )}
      
      {/* Search Results Paper */}
      {openPaper && (
        <Paper
          ref={paperRef} 
          onScroll={handleScroll}
          sx={{
            position: 'absolute',
            top: 110,
            right: 150,
            width: '25%',
            maxHeight: '400px', 
            overflowY: 'auto', 
            borderRadius: 2, 
            boxShadow: 3,
            padding: 2, 
            backgroundColor: '#fff', 
            zIndex: 100
          }}
        >
          {filteredSearchResults.length > 0 ? (
            <List>
              {filteredSearchResults.map((result) => (
                <ListItem 
                  key={result.id}
                  onClick={() => {
                    navigate(`/news/editnews/${result.id}`);
                    setOpenPaper(false);
                  }}
                  sx={{
                    borderBottom: '1px solid #e0e0e0',
                    '&:last-child': { borderBottom: 'none' }, 
                    '&:hover': {
                      backgroundColor: '#f5f5f5', 
                      cursor: 'pointer'
                    },
                    padding: '8px'
                  }}
                >   
                  <ListItemText
                    primary={
                      <Typography variant="subtitle1" fontWeight="bold" color="primary" sx={{ fontSize: '14px' }}>
                        {result?.content?.en?.short_summary || "Untitled"}
                      </Typography>
                    }
                  />
                </ListItem>
              ))}
            </List>
          ) : (
            <Typography variant="body2" align="center" color="textSecondary" sx={{ py: 2 }}>
              {searchTerm ? `No results found for "${searchTerm}"` : "Type to search news"}
            </Typography>
          )}
        </Paper>
      )}
    </Container>
  );
};

export default News;