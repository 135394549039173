import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const CardEndpoint = ({ shouldRedirect }) => {
  const navigate = useNavigate();
//   //console.log("hiii");
  

  useEffect(() => {
    if (shouldRedirect) {
      navigate('/news?tab=='); // Only navigate when shouldRedirect is true
    }
  }, [shouldRedirect, navigate]);

  return 
  <></>;
};

export default CardEndpoint;
