import React, { useState } from 'react'
import {  Modal, IconButton, Box, Typography, Button } from '@mui/material';
import { Close } from '@mui/icons-material';
import styled from '@emotion/styled';


const modalStyle = {
    position: 'absolute',
    top: '60%',
    left: '18%',
    transform: 'translate(-50%, -50%)',
    width: '30%',
    height: '370px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};
const modalsStyle = {
    position: 'absolute',
    top: '60%',
    left: '78%',
    transform: 'translate(-50%, -50%)',
    width: '30%',
    height: '370px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};
//VisuallyInput
const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});


const UploadImageModel = ({ handleFileChange, open, handleClose ,index }) => {
    const [dragOver, setDragOver] = useState(false);
    // Handle drag over events
    const handleDragOver = (e) => {
        e.preventDefault();
        setDragOver(true);
    };

    // Handle drag leave
    const handleDragLeave = () => {
        setDragOver(false);
    };

    // Handle drop files
    const handleDrop = (e) => {
        e.preventDefault();
        setDragOver(false);
        const files = e.dataTransfer.files;
        if (files.length) {
            handleFileChange({ target: { files } });
        }
    };

    return (
        <Box sx={{ height: '300px', width: '55%', display: 'flex', justifyContent: 'center', alignItems: 'center', border: '2px solid #ccc', borderRadius: '8px', overflow: 'hidden', padding: '16px' }}>
            
      <Modal open={open} onClose={handleClose}>
                <Box sx={open === 1 ? modalStyle : open === 2 ? modalsStyle:''}>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="h6">Upload Image { index}</Typography>
                  <IconButton onClick={handleClose}>
                      <Close />
                  </IconButton>
              </Box>
              <Box
                  onDragOver={handleDragOver}
                  onDragLeave={handleDragLeave}
                  onDrop={handleDrop}
                  sx={{
                      width: '75%',
                      height: '200px',
                      marginLeft: '40px',
                      border: dragOver ? '2px dashed #1976d2' : '2px dashed #ccc',
                      borderRadius: '8px',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      rowGap: '20px',
                      padding: '20px',
                      mt: 5,
                    }}>
                  <>
                      <Button component="label" variant="contained" sx={{ mt: 0 }}>
                          Browse
                          <VisuallyHiddenInput type="file" onChange={(e) => handleFileChange(e, index)} />
                      </Button>
                      <Typography>or Drag and drop an image</Typography>
                  </>
              </Box>
          </Box>
      </Modal>
    </Box>
  )
}

export default UploadImageModel;