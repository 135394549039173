import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { TextField, Chip, Divider, FormLabel, RadioGroup, FormControlLabel, Radio, Container, Modal, Autocomplete, FormGroup } from '@mui/material';
import CloudUpload from '../../components/button/Cloudupload';
import {Grid} from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import Editor from './Editor';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { createnews, deletenews, getcategories, getcity, getnewsad, getnewsbyid, getstates, gettopics, updatenews, uploadimage } from '../../slices/news';

 
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  p: 2,
  borderRadius: 2
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function Addnews({setLoader}) {

  const params = useParams();

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const {categories: catData} = useSelector((state) => state.news);

  const {topics: topData} = useSelector((state) => state.news);

  const {newsAd: adData} = useSelector((state) => state.news);

  const {cities: cityData} = useSelector((state) => state.news);

  const {states: stateData} = useSelector((state) => state.news);

  const [categoryName, setCategoryName] = React.useState(null);

  const [topicsname, setTopicsName] = React.useState(null);

  const [tanewsad, settaNewsAd] = React.useState(null);

  const [ennewsad, setenNewsAd] = React.useState(null);

  const [cityname, setCityName] = React.useState(null);

  const [statename, setStateName] = React.useState(null);

  const [file1, setFile1] = useState(null);

  const [file2, setFile2] = useState(null);

  const [inputValue, setInputValue] = useState('');
  const [hashtags, setHashtags] = useState([]);

  const [content1, setContent1] = useState('');

  const [content2, setContent2] = useState('');


  const [newsdata, setNewsdata] = useState({
        category_id:"", 
        city_id: "",
        content:{
          ta:{
            headlines: "",
            title: "",
            web_content: "",
            news_ad_id : ""
          },
          en:{
            headlines: "",
            title: "",
            web_content: "",
            news_ad_id : ""
          }
        },
        is_breaking: false,
        is_international_news: false,
        is_national_news:false,
        is_state_news: false,
        meta_description: "",
        meta_title: "",
        news_source:{
          name: "",
          url: ""
        },
        news_type: "web",
        state_id: "",
        tags: hashtags,
        topics: [
          topicsname?.id
        ]
  })

  //console.log(newsdata)

  const handleValue = (e, name) => {
    //console.log(file2)
    setNewsdata({
      ...newsdata,
      [name]: e.target.value,
    });

    if(name==='metatitle'){
      setNewsdata({
        ...newsdata,
        meta_title: e.target.value
      });
    }
    if(name==='metadescription'){
      setNewsdata({
        ...newsdata,
        meta_description: e.target.value
      });
    }
    if(name==='nsname'){
      setNewsdata({
        ...newsdata,
        news_source:
          {
          ...newsdata.news_source,
          name: e.target.value
          },
      });
    }
    if(name==='nsurl'){
      setNewsdata({
        ...newsdata,
        news_source:
          {
          ...newsdata.news_source,
          url: e.target.value
          },
      });
    }
    if(name==='tntitle'){
      setNewsdata({
        ...newsdata,
        content:{
          ...newsdata.content,
          ta:{
            ...newsdata.content.ta,
            title: e.target.value
          }
        }
      });
    }
    if(name==='tnheadlines'){
      setNewsdata({
        ...newsdata,
        content:{
          ...newsdata.content,
          ta:{
            ...newsdata.content.ta,
            headlines: e.target.value
          }
        }
      });
    }
    if(name==='tnshort_summary'){
      setNewsdata({
        ...newsdata,
        content:{
          ...newsdata.content,
          ta:{
            ...newsdata.content.ta,
            short_summary: e.target.value
          }
        }
      });
    }
    if(name==='entitle'){
      setNewsdata({
        ...newsdata,
        content:{
          ...newsdata.content,
          en:{
            ...newsdata.content.en,
            title: e.target.value
          }
        }
      });
    }
    if(name==='enheadlines'){
      setNewsdata({
        ...newsdata,
        content:{
          ...newsdata.content,
          en:{
            ...newsdata.content.en,
            headlines: e.target.value
          }
        }
      });
    }
    if(name==='enshort_summary'){
      setNewsdata({
        ...newsdata,
        content:{
          ...newsdata.content,
          en:{
            ...newsdata.content.en,
            short_summary: e.target.value
          }
        }
      });
    }
  }

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const value = inputValue.trim();
      if (value) {
        setHashtags([...hashtags, value]);
        setInputValue('');
      }
    }
  };

  const handleDeleteChip = (index) => {
    const updatedHashtags = [...hashtags];
    updatedHashtags.splice(index, 1);
    setHashtags(updatedHashtags);
  };

  const handleCheckboxChange = (event) => {
    setNewsdata({
      ...newsdata,
      [event.target.name]: event.target.checked,
    });
  };

  const arrowBack = () => {
    navigate(-1);
  };

  useEffect(()=>{
    setLoader(true)
    const formData = {
      "filter": {},
      "sort": "_id",
      "order": 1,
      "page": 0,
      "page_size": 100
    }
      dispatch(getcategories(formData))
      dispatch(gettopics(formData))
      dispatch(getnewsad(formData))
      dispatch(getcity())
      dispatch(getstates())
      // dispatch(getnewsbyid(params.newsid))
      .then((data) => {
        const upData = data.payload.newsdata.data
        setNewsdata(upData);
        setCategoryName(upData?.category);
        // setTopicsName(newsdata?.topics?.length > 0 && newsdata?.topics[0]?.multilingual_names?.en);
        setHashtags(upData.tags);
        settaNewsAd(upData?.content?.ta?.news_ad);
        setenNewsAd(upData?.content?.en?.news_ad)
        setFile1(upData?.content?.ta?.images);
        setFile2(upData?.content?.en?.images);
        setContent1(upData?.content?.ta?.web_content);
        setContent2(upData?.content?.en?.web_content);
        setLoader(false);
      })
      .catch(()=>{
        setLoader(false);
      })
  }, [])

  const handleSubmit = (e) => {
    e.preventDefault();
      const formData = {
        category_id: categoryName?.id, 
        city_id: cityname.id,
        content:{
          ta:{
            headlines: newsdata.content.ta.headlines,
            title: newsdata.content.ta.title,
            web_content: content1,
            news_ad_id : tanewsad?.id
          },
          en:{
            headlines: newsdata.content.en.headlines,
            title: newsdata.content.en.title,
            web_content: content2,
            news_ad_id : ennewsad?.id
          }
        },
        is_breaking: newsdata.is_breaking,
        is_international_news: newsdata.is_international_news,
        is_national_news: newsdata.is_national_news,
        is_state_news: newsdata.is_state_news,
        meta_description: newsdata.meta_description,
        meta_title: newsdata.meta_title,
        news_source:{
          name: newsdata.news_source.name,
          url: newsdata.news_source.url
        },
        news_type: newsdata.news_type,
        state_id: statename.id,
        tags: hashtags,
        topics: [
          topicsname.id
        ]
      };
      setLoader(true);
      dispatch(createnews(formData))
      .then((response) => {
        //console.log(response?.payload?.data?.data?.data?.id);
        const news_id = response?.payload?.data?.data?.data?.id;
        const formVata = new FormData();
        formVata.append('image', file2); 
        dispatch(uploadimage({news_id, formVata}))
        .then(()=>{
          setLoader(false);
          navigate("/news");
        })
      })
      .catch(()=>{
        setLoader(false);
      })
  };

  return (
    <Container maxWidth="fluid" className="content">
        <Box
           component="form"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
        >
            <Grid container spacing={2} sx={{paddingBottom: 2}}>
            <Grid
            item
            xs={8}
            sx={{
              paddingTop: 0,
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >

            <ArrowBackIcon onClick={arrowBack} sx={{ cursor: "pointer" }} />
            <Typography variant="h5"  sx={{fontWeight:'bold'}}>Add News</Typography>
          </Grid>
                <Grid item xs={4} sx={{textAlign: 'end'}}>
                <Button type='submit' size='large' sx={{marginTop: 3}} variant='contained'>Create News</Button>
                </Grid>
                <Grid item xs={6}>
                <FormControl fullWidth sx={{mb: 2}}>
        <InputLabel id="demo-simple-select-label">News Type</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={newsdata?.news_type}
          label="News Type"
          onChange={(e)=>handleValue(e, 'news_type')}
        >
          <MenuItem value='short'>short</MenuItem>
          <MenuItem value='web'>web</MenuItem>
        </Select>
      </FormControl>
                <Autocomplete
      options={catData}
      value={categoryName} 
      getOptionLabel={(option) => `${option.name}`}
      renderInput={(params) => <TextField {...params} sx={{marginBottom: 2}} label="Select Category" variant="outlined" />}
      onChange={(event, newValue) => {
        setCategoryName(newValue);
      }}
    />
                        <Autocomplete
      options={topData}
      value={topicsname} 
      getOptionLabel={(option) => `${option.name}`}
      renderInput={(params) => <TextField {...params} sx={{marginBottom: 2}} label="Select Topics" variant="outlined" />}
      onChange={(event, newValue) => {
        setTopicsName(newValue);
      }}
    />
                    <Autocomplete
      options={cityData}
      value={cityname} 
      getOptionLabel={(option) => `${option.name}`}
      renderInput={(params) => <TextField {...params} sx={{marginBottom: 2}} label="Select City" variant="outlined" />}
      onChange={(event, newValue) => {
        setCityName(newValue);
      }}
    />
                        <Autocomplete
      options={stateData}
      value={statename} 
      getOptionLabel={(option) => `${option.name}`}
      renderInput={(params) => <TextField {...params} sx={{marginBottom: 2}} label="Select State" variant="outlined" />}
      onChange={(event, newValue) => {
        setStateName(newValue);
      }}
    />
                </Grid>
                <Grid item xs={3}>
                {newsdata?.news_type !== "short" ? 
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox
            checked={newsdata.is_breaking}
            onChange={handleCheckboxChange}
            name="is_breaking"
          />
        }
        label="Breaking News"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={newsdata.is_international_news}
            onChange={handleCheckboxChange}
            name="is_international_news"
          />
        }
        label="International News"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={newsdata.is_national_news}
            onChange={handleCheckboxChange}
            name="is_national_news"
          />
        }
        label="National News"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={newsdata.is_state_news}
            onChange={handleCheckboxChange}
            name="is_state_news"
          />
        }
        label="State News"
      />
    </FormGroup>
    : null}
                </Grid>
                <Grid item xs={3}>
                <TextField sx={{marginBottom: 2}} fullWidth id="outlined-basic" label="News Source Name" variant="outlined" InputLabelProps={{ required: true }}
                value={newsdata?.news_source?.name}
                onChange={(e)=>handleValue(e, 'nsname')}
                />
                                <TextField sx={{marginBottom: 2}} fullWidth id="outlined-basic" label="News Source URL" variant="outlined" InputLabelProps={{ required: true }}
                value={newsdata?.news_source?.url}
                onChange={(e)=>handleValue(e, 'nsurl')}
                />
                {newsdata?.news_type === 'short' ? 
                                <Box sx={{marginBottom: 3}}>
                <Typography variant='body1' sx={{mb: 1}}>Image *</Typography>
                <CloudUpload file={file2} setFile={setFile2} setLoader={setLoader}/>
                </Box>
                : null}
                </Grid>
                <Grid item xs={6}>
                {newsdata?.news_type !== "short" ? 
                <TextField sx={{marginBottom: 2}} fullWidth id="outlined-basic" label="Meta Title" variant="outlined" InputLabelProps={{ required: true }}
                value={newsdata?.meta_title}
                onChange={(e)=>handleValue(e, 'metatitle')}
                />
                : null}
                {newsdata?.news_type !== "short" ? 
                <TextField sx={{marginBottom: 2}} fullWidth id="outlined-basic" label="Meta Description" variant="outlined" InputLabelProps={{ required: true }}
                 value={newsdata?.meta_description}
                onChange={(e)=>handleValue(e, 'metadescription')} 
                />
                : null}
                {newsdata?.news_type !== "short" ? 
                                <Autocomplete
      options={adData}
      value={tanewsad} 
      getOptionLabel={(option) => `${option.title}`}
      renderInput={(params) => <TextField {...params} sx={{marginBottom: 2}} label="Select TA News Ad" variant="outlined" />}
      onChange={(event, newValue) => {
        settaNewsAd(newValue);
      }}
    />
    : null}
                <TextField sx={{marginBottom: 2}} fullWidth id="outlined-basic" label="TA Title" variant="outlined" InputLabelProps={{ required: true }}
                value={newsdata?.content?.ta?.title}
                onChange={(e)=>handleValue(e, 'tntitle')}
                />
                <TextField sx={{marginBottom: 2}} fullWidth id="outlined-basic" label="TA Headlines" variant="outlined" InputLabelProps={{ required: true }}
                 value={newsdata?.content?.ta?.headlines}
                onChange={(e)=>handleValue(e, 'tnheadlines')} 
                />
                {/* <TextField sx={{marginBottom: 2}} multiline rows={2} fullWidth id="outlined-basic" label="Short Summary" variant="outlined" InputLabelProps={{ required: true }}
                  value={newsdata?.content?.ta?.short_summary}
                onChange={(e)=>handleValue(e, 'tnshort_summary')}
                />
                <Box sx={{marginBottom: 3}}>
                <Typography variant='body1' sx={{mb: 1}}>Summary Image *</Typography>
                <CloudUpload file={file1} setFile={setFile1} setLoader={setLoader}/>
                </Box> */}
                {newsdata?.news_type !== "short" ? 
                <>
                <Typography variant='body1' sx={{mb: 1}}>TA Content *</Typography>
                <Box sx={{height:'50%'}}>
                <Editor content={content1} setContent={setContent1} placeholder={'Write something...'} />
                </Box>
                </>
                : null}
                </Grid>
                <Grid item xs={6}>
                {newsdata?.news_type !== "short" ? 
                <Box sx={{marginBottom: 3}}>
                <Typography variant='body1' sx={{mb: 1}}>Image *</Typography>
                <CloudUpload file={file2} setFile={setFile2} setLoader={setLoader}/>
                </Box>
                : null}
                {newsdata?.news_type !== "short" ? 
                <div>
      <TextField
        label="Tags"
        variant="outlined"
        fullWidth
        value={inputValue}
        InputLabelProps={{ required: true }}
        onChange={handleInputChange}
        onKeyDown={handleInputKeyDown}
        placeholder="Press Enter to add tags"
      />
      <div style={{marginTop: 10}}>
        {hashtags.map((tag, index) => (
          <Chip
            key={index}
            label={`#${tag}`}
            onDelete={() => handleDeleteChip(index)}
            sx={{marginRight: 1}}
          />
        ))}
      </div>
    </div>
    : null}
    {newsdata?.news_type !== "short" ? 
                <Autocomplete
      options={adData}
      value={ennewsad} 
      getOptionLabel={(option) => `${option.title}`}
      renderInput={(params) => <TextField {...params} sx={{marginBottom: 2}} label="Select EN News Ad" variant="outlined" />}
      onChange={(event, newValue) => {
        setenNewsAd(newValue);
      }}
    />
    : null}
                <TextField sx={{marginBottom: 2}} fullWidth id="outlined-basic" label="EN Title" variant="outlined" InputLabelProps={{ required: true }}
                value={newsdata?.content?.en?.title}
                onChange={(e)=>handleValue(e, 'entitle')}
                />
                <TextField sx={{marginBottom: 2}} fullWidth id="outlined-basic" label="EN Headlines" variant="outlined" InputLabelProps={{ required: true }}
                 value={newsdata?.content?.en?.headlines}
                onChange={(e)=>handleValue(e, 'enheadlines')} 
                />
                {/* <TextField sx={{marginBottom: 2}} multiline rows={2} fullWidth id="outlined-basic" label="Short Summary" variant="outlined" InputLabelProps={{ required: true }}
                  value={newsdata?.content?.en?.short_summary}
                onChange={(e)=>handleValue(e, 'enshort_summary')}
                /> */}
                {newsdata?.news_type !== "short" ? 
                  <>
                <Typography variant='body1' sx={{mb: 1}}>EN Content *</Typography>
                <Box sx={{height:'50%'}}>
                <Editor content={content2} setContent={setContent2} placeholder={'Write something...'} />
                </Box>
                </>
                : null}
                </Grid>
            </Grid>
       </Box>
    </Container>
  );
}