import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Autocomplete, Button, Card, Container, Grid, TextField, Typography, IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import MediaCard from '../card/Card';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getAdminStats, getcategories } from '../../slices/news';
import { useDispatch, useSelector } from 'react-redux';
import StatusDisplay from './statsDisplay';
import News from '../../pages/news/News';
import { setStatus } from '../../slices/Status';
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Loader from '../loader/Loader';
import loaderFile from "../../assets/gif/loader.gif"

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node, 
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs({ setQuery, setPage, newsData, setNewstype, setLoader, setCity, city, cityData, setCategory, category, startDate, setStartDate, endDate, setEndDate, newsSourceName, setNewsSourceName, bottomLoader, filters, setFilters }) {
  const [value, setValue] = React.useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [stats, setstats] = React.useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const status = useSelector((state) => state.status);
  const { categories: catData = [] } = useSelector((state) => state.news) || { categories: [] };

  React.useEffect(() => {
    const formData = {
      "filter": {},
      "sort": "_id",
      "order": 1,
      "page": 0,
      "page_size": 100
    }
    dispatch(getcategories(formData)) 
    dispatch(getAdminStats())
      .then((data) => {
        const updata = data.payload.data.data
        setstats(updata);
      })
      .catch(() => {
        setLoader(false);
      })
  }, [])

  // Extract the news type from the search parameters
  React.useEffect(() => {
    const searchQuery = searchParams.get('tab');
    
    if (searchQuery === 'short') {
      setValue(0);
      setNewstype("short");
    } else if (searchQuery === 'web') {
      setValue(1);
      setNewstype("web");
    }
    
  }, [searchParams, setNewstype]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) {
      setNewstype("short");
      navigate('?tab=short'); 
    } else if (newValue === 1) {
      setNewstype("web");
      navigate('?tab=web'); 
    }
  };
  
  const handleEdit = (newsItem) => {
    if (newsItem.type === 'short') {
      setValue(0);
      setNewstype("short");
      navigate('?tab=short'); 
    } else if (newsItem.type === 'web') {
      setValue(1);
      setNewstype("web");
      navigate('?tab=web'); 
    }
  };

  React.useEffect(() => {
    const newsStatus = localStorage.getItem("newsStatus");
    if (newsStatus) {
      dispatch(setStatus(newsStatus));
    }
  }, [])
  
  const handleStatus = (value) => {
    dispatch(setStatus(value));
    localStorage.setItem("newsStatus", value);
  }
  
  const setqueryparams = (key, value) => {
    const newParams = new URLSearchParams(searchParams);
    newParams.set(key, value); 
    setSearchParams(newParams);
  };

  // Handle date changes with automatic filtering
  const handleStartDateChange = (newValue) => {
    setStartDate(newValue);
    
    // Update URL with the formatted date or remove if null
    const newParams = new URLSearchParams(searchParams);
    if (newValue) {
      const formattedDate = newValue.format('YYYY-MM-DD');
      newParams.set("start_date", formattedDate);
    } else {
      newParams.delete("start_date");
    }
    
    setSearchParams(newParams);
    setPage(1); // Reset to page 1 when date changes
  };

  const handleEndDateChange = (newValue) => {
    setEndDate(newValue);
    
    // Update URL with the formatted date or remove if null
    const newParams = new URLSearchParams(searchParams);
    if (newValue) {
      const formattedDate = newValue.format('YYYY-MM-DD');
      newParams.set("end_date", formattedDate);
    } else {
      newParams.delete("end_date");
    }
    
    setSearchParams(newParams);
    setPage(1); // Reset to page 1 when date changes
  };

  // Clear date handler functions
  const handleClearStartDate = () => {
    handleStartDateChange(null);
  };

  const handleClearEndDate = () => {
    handleEndDateChange(null);
  };

  // Find the selected category safely
  const selectedCategory = React.useMemo(() => {
    if (!catData || !Array.isArray(catData)) return null;
    const categoryName = searchParams.get("category");
    return categoryName ? catData.find(c => c.name === categoryName) || null : null;
  }, [catData, searchParams]);

  // Find the selected city safely
  const selectedCity = React.useMemo(() => {
    if (!cityData || !Array.isArray(cityData)) return null;
    const cityName = searchParams.get("city");
    return cityName ? cityData.find(c => c.name === cityName) || null : null;
  }, [cityData, searchParams]);

  // Custom date picker component with clear icon
  const DatePickerWithClear = ({ label, value, onChange, onClear }) => (
    <Box sx={{ position: 'relative', width: '100%' }}>
      <DatePicker
        label={label}
        value={value}
        onChange={onChange}
        slotProps={{
          textField: {
            fullWidth: true
          }
        }}
      />
      {value && (
        <IconButton
          sx={{
            position: 'absolute',
            right: '40px',
            top: '50%',
            transform: 'translateY(-50%)',
            zIndex: 1,
            padding: '4px'
          }}
          onClick={onClear}
          size="small"
        >
          <ClearIcon fontSize="small" />
        </IconButton>
      )}
    </Box>
  );

  return (
    <Box sx={{ width: '100%' }}>
      <Box>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Short News" {...a11yProps(0)} sx={{ textTransform: 'initial', minWidth: 0, fontWeight: 'bold' }} />
          <Tab label="Web News" {...a11yProps(1)} sx={{ textTransform: 'initial', minWidth: 0, fontWeight: 'bold' }} />
        </Tabs>
      </Box>
      
      {/* Tab Panel for Short News */}
      <CustomTabPanel value={value} index={0}>
        <Box sx={{ display: 'flex', gap: 1, mt: 2 }}>
          <Button variant={status === 'pending' ? 'contained' : 'outlined'} onClick={() => handleStatus("pending")} sx={{ borderRadius: 10 }}>Pending</Button>
          <Button variant={status === 'approved' ? 'contained' : 'outlined'} onClick={() => handleStatus("approved")} sx={{ borderRadius: 10 }}>Approved</Button>
          <Button variant={status === 'rejected' ? 'contained' : 'outlined'} onClick={() => handleStatus("rejected")} sx={{ borderRadius: 10 }}>Rejected</Button>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: 2, marginTop: 2 }}>
          {/* Date Filters */}
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box sx={{ display: 'flex', gap: '20px', width: '50%' }}>
              <DatePickerWithClear
                label="Start Date"
                value={startDate}
                onChange={handleStartDateChange}
                onClear={handleClearStartDate}
              />
              <DatePickerWithClear
                label="End Date"
                value={endDate}
                onChange={handleEndDateChange}
                onClear={handleClearEndDate}
              />
            </Box>
          </LocalizationProvider>

        
        
          {/* Source Name Filter */}
          <Autocomplete
            sx={{ width: '20%' }}
            fullWidth
            id="news-source-name"
            options={['PTI',"slack", "NewsIT Reporter", "NewsIT Team", "Public Report"]} 
            value={searchParams.get("source") || null}
            onChange={(e, newValue) => {
              setqueryparams("source", newValue || '');
              setNewsSourceName(newValue || '');
              setPage(1);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Source Name"
                variant="outlined"
                InputLabelProps={{ required: false }}
              />
            )}
          />
        
          <Box sx={{ display: 'flex', gap: '20px', width: '50%', marginTop: 2.5 }}>
            {/* Category Filter */}
            <Autocomplete
              sx={{ width: '50%' }}
              options={Array.isArray(catData) ? catData : []} 
              value={selectedCategory}
              getOptionLabel={(option) => option?.name || ""}
              renderInput={(params) => (
                <TextField 
                  {...params} 
                  sx={{ marginBottom: 2 }} 
                  label="Select Category" 
                  variant="outlined" 
                />
              )}
              onChange={(event, newValue) => {
                if (newValue) {
                  setqueryparams("category", newValue.name);
                  setCategory(newValue);
                  setCity(null);
                  setQuery(newValue.name);
                } else {
                  setqueryparams("category", "");
                  setCategory(null);
                  setQuery("");
                }
                setPage(1); // Reset to page 1 when category changes
              }}
            />
            
            {/* City Filter */}
            <Autocomplete
              sx={{ width: '50%' }}
              options={Array.isArray(cityData) ? cityData : []}
              value={selectedCity}
              getOptionLabel={(option) => option?.name || ""}
              renderInput={(params) => (
                <TextField 
                  {...params} 
                  sx={{ marginBottom: 2.5 }} 
                  label="Select City" 
                  variant="outlined" 
                />
              )}
              onChange={(event, newValue) => {
                setCity(newValue);
                setCategory(null);
                if (newValue) {
                  setqueryparams("city", newValue.name);
                  setQuery(newValue.name);
                } else {
                  setqueryparams("city", "");
                  setQuery("");
                }
                setPage(1); // Reset to page 1 when city changes
              }}
            />
          </Box>
        </Box>

        {/* News Type Filters */}
        <Box sx={{ display: 'flex', gap: 2, mt: 2, mb: 2 }}>
          <Button 
            variant={filters.isStateNews ? 'contained' : 'outlined'} 
            onClick={() => {
              const newValue = !filters.isStateNews;
              setFilters({...filters, isStateNews: newValue});
              setqueryparams("is_state_news", String(newValue));
              setPage(1);
            }}
            sx={{ borderRadius: 4 }}
          >
            State News
          </Button>
          <Button 
            variant={filters.isNationalNews ? 'contained' : 'outlined'} 
            onClick={() => {
              const newValue = !filters.isNationalNews;
              setFilters({...filters, isNationalNews: newValue});
              setqueryparams("is_national_news", String(newValue));
              setPage(1);
            }}
            sx={{ borderRadius: 4 }}
          >
            National News
          </Button>
          <Button 
            variant={filters.isInternationalNews ? 'contained' : 'outlined'} 
            onClick={() => {
              const newValue = !filters.isInternationalNews;
              setFilters({...filters, isInternationalNews: newValue});
              setqueryparams("is_international_news", String(newValue));
              setPage(1);
            }}
            sx={{ borderRadius: 4 }}
          >
            International News
          </Button>
        </Box>
        
        <Grid container spacing={2} sx={{ mt: 0, mb: 5 }}>
          {newsData?.map((val, index) => (
            <MediaCard status={status} setLoader={setLoader} key={index} val={val} onEdit={handleEdit} />
          ))}
          {bottomLoader && 
            <Container sx={{display:'flex', alignItems:'center', justifyContent:'center', width:"100%"}}>
              <img src={loaderFile} alt="my-gif" width={400}/>
            </Container>
          }
        </Grid>
      </CustomTabPanel>
     
      {/* Tab Panel for Web News */}
      <CustomTabPanel value={value} index={1}>
        <Box sx={{ display: 'flex', gap: 1, mt: 2 }}>
          <Button variant={status === 'pending' ? 'contained' : 'outlined'} onClick={() => handleStatus("pending")} sx={{ borderRadius: 10 }}>Pending</Button>
          <Button variant={status === 'approved' ? 'contained' : 'outlined'} onClick={() => handleStatus("approved")} sx={{ borderRadius: 10 }}>Approved</Button>
          <Button variant={status === 'rejected' ? 'contained' : 'outlined'} onClick={() => handleStatus("rejected")} sx={{ borderRadius: 10 }}>Rejected</Button>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: 2, marginTop: 2 }}>
          {/* Date Filters */}
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box sx={{ display: 'flex', gap: '20px', width: '50%' }}>
              <DatePickerWithClear
                label="Start Date"
                value={startDate}
                onChange={handleStartDateChange}
                onClear={handleClearStartDate}
              />
              <DatePickerWithClear
                label="End Date"
                value={endDate}
                onChange={handleEndDateChange}
                onClear={handleClearEndDate}
              />
            </Box>
          </LocalizationProvider>
        
          {/* Source Name Filter */}
          <Autocomplete
            sx={{ width: '20%' }}
            fullWidth
            id="news-source-name"
            options={['PTI', "slack", "NewsIT Reporter", "NewsIT Team", "Public Report"]} 
            value={searchParams.get("source") || null}
            onChange={(e, newValue) => {
              setqueryparams("source", newValue || '');
              setNewsSourceName(newValue || '');
              setPage(1);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Source Name"
                variant="outlined"
                InputLabelProps={{ required: false }}
              />
            )}
          />
        
          <Box sx={{ display: 'flex', gap: '20px', width: '50%', marginTop: 2.5 }}>
            {/* Category Filter */}
            <Autocomplete
              sx={{ width: '50%' }}
              options={Array.isArray(catData) ? catData : []}
              value={selectedCategory}
              getOptionLabel={(option) => option?.name || ""}
              renderInput={(params) => (
                <TextField 
                  {...params} 
                  sx={{ marginBottom: 2 }} 
                  label="Select Category" 
                  variant="outlined" 
                />
              )}
              onChange={(event, newValue) => {
                if (newValue) {
                  setqueryparams("category", newValue.name);
                  setCategory(newValue);
                  setCity(null);
                  setQuery(newValue.name);
                } else {
                  setqueryparams("category", "");
                  setCategory(null);
                  setQuery("");
                }
                setPage(1); // Reset to page 1 when category changes
              }}
            />
            
            {/* City Filter */}
            <Autocomplete
              sx={{ width: '50%' }}
              options={Array.isArray(cityData) ? cityData : []}
              value={selectedCity} 
              getOptionLabel={(option) => option?.name || ""}
              renderInput={(params) => (
                <TextField 
                  {...params} 
                  sx={{ marginBottom: 2.5 }} 
                  label="Select City" 
                  variant="outlined" 
                />
              )}
              onChange={(event, newValue) => {
                setCity(newValue);
                setCategory(null);
                if (newValue) {
                  setqueryparams("city", newValue.name);
                  setQuery(newValue.name);
                } else {
                  setqueryparams("city", "");
                  setQuery("");
                }
                setPage(1); // Reset to page 1 when city changes
              }}
            />
          </Box>
        </Box>

        {/* News Type Filters */}
        <Box sx={{ display: 'flex', gap: 2, mt: 2, mb: 2 }}>
          <Button 
            variant={filters.isStateNews ? 'contained' : 'outlined'} 
            onClick={() => {
              const newValue = !filters.isStateNews;
              setFilters({...filters, isStateNews: newValue});
              setqueryparams("is_state_news", String(newValue));
              setPage(1);
            }}
            sx={{ borderRadius: 4 }}
          >
            State News
          </Button>
          <Button 
            variant={filters.isNationalNews ? 'contained' : 'outlined'} 
            onClick={() => {
              const newValue = !filters.isNationalNews;
              setFilters({...filters, isNationalNews: newValue});
              setqueryparams("is_national_news", String(newValue));
              setPage(1);
            }}
            sx={{ borderRadius: 4 }}
          >
            National News
          </Button>
          <Button 
            variant={filters.isInternationalNews ? 'contained' : 'outlined'} 
            onClick={() => {
              const newValue = !filters.isInternationalNews;
              setFilters({...filters, isInternationalNews: newValue});
              setqueryparams("is_international_news", String(newValue));
              setPage(1);
            }}
            sx={{ borderRadius: 4 }}
          >
            International News
          </Button>
        </Box>

        <Grid container spacing={2} sx={{ mt: 0, mb: 5 }}>
          {newsData?.map((val, index) => (
            <MediaCard setLoader={setLoader} key={index} val={val} onEdit={handleEdit} />
          ))}
          {bottomLoader && 
            <Container sx={{display:'flex', alignItems:'center', justifyContent:'center', width:"100%"}}>
              <img src={loaderFile} alt="my-gif" width={400}/>
            </Container>
          }
        </Grid>
      </CustomTabPanel>
    </Box>
  );
}