import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { TextField, Chip, Divider, FormLabel, RadioGroup, FormControlLabel, Radio, Container, Modal, Autocomplete, FormGroup } from '@mui/material';
import CloudUpload from '../../components/button/Cloudupload';
import {Grid} from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import Editor from './Editor';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { deletenews, getcategories, getcity, getnewsad, getnewsbyid, getstates, gettopics, updateaction, updatenews, updatepublish, uploadimage } from '../../slices/news';
import { Update } from '@mui/icons-material';
import CardEndpoint from '../../components/cardsEndpoint/CardEndpoint';
import { setStatus } from '../../slices/Status';
  

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  p: 2,
  borderRadius: 2
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function Editnews({setLoader}) {

  const {newsid}= useParams();
  const [shouldRedirect, setShouldRedirect] = useState(false); 

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const {categories: catData} = useSelector((state) => state.news);

  const {topics: topData} = useSelector((state) => state.news);

  const {newsAd: adData} = useSelector((state) => state.news);

  const {cities: cityData} = useSelector((state) => state.news);

  const {states: stateData} = useSelector((state) => state.news);

  const [newstype, setNewstype] = useState(null);

  const [categoryName, setCategoryName] = React.useState(null);

  const [topicsname, setTopicsName] = React.useState(null);

  const [tanewsad, settaNewsAd] = React.useState(null);

  const [ennewsad, setenNewsAd] = React.useState(null);

  const [cityname, setCityName] = React.useState(null);

  const [statename, setStateName] = React.useState(null);

  const [metatitle, setMetatitle] = React.useState("");

  const [metadesc, setMetadesc] = React.useState("");

  const [file1, setFile1] = useState(null);
  

  const [file2, setFile2] = useState(null);
  const [file3, setFile3] = useState(null);

  const [inputValue, setInputValue] = useState('');
  const [hashtags, setHashtags] = useState([]);

  const [content1, setContent1] = useState('');

  const [content2, setContent2] = useState('');


  const [newsdata, setNewsdata] = useState({
    category_id:"", 
    city_id: "",
    content:{
      ta:{
        headlines: "",
        title: "",
        web_content: "",
        news_ad_id : ""
      },
      en:{
        headlines: "",
        title: "",
        web_content: "",
        news_ad_id : ""
      }
    },
    is_breaking: false,
    is_international_news: false,
    is_national_news:false,
    is_state_news: false,
    meta_description: "",
    meta_title: "",
    news_source:{
      name: "",
      url: ""
    },
    news_type: "",
    state_id: "",
    tags: hashtags,
    topics: [
      topicsname?.id
    ]
  })
  //console.log(newsdata?.category_id);
  


  const handleValue = (e, name) => {
    setNewsdata({
      ...newsdata,
      [name]: e.target.value,
    });
    if(name==='metatitle'){
      setMetatitle(e.target.value)
    }
    if(name==='metadescription'){
      setMetadesc(e.target.value)
    }
    if(name==='nsname'){
      setNewsdata({
        ...newsdata,
        news_source:
          {
          name: e.target.value
          },
      });
    }
    if(name==='nsurl'){
      setNewsdata({
        ...newsdata,
        news_source:
          {
          url: e.target.value
          },
      });
    }
    if(name==='tntitle'){
      setNewsdata({
        ...newsdata,
        content:{
          ...newsdata?.content,
          ta:{
            ...newsdata?.content?.ta,
            title: e.target.value
          }
        }
      });
    }
    if(name==='tnheadlines'){
      setNewsdata({
        ...newsdata,
        content:{
          ...newsdata.content,
          ta:{
            ...newsdata.content.ta,
            headlines: e.target.value
          }
        }
      });
    }
    if(name==='tnshort_summary'){
      setNewsdata({
        ...newsdata,
        content:{
          ...newsdata.content,
          ta:{
            ...newsdata.content.ta,
            short_summary: e.target.value
          }
        }
      });
    }
    if(name==='entitle'){
      setNewsdata({
        ...newsdata,
        content:{
          ...newsdata.content,
          en:{
            ...newsdata.content.en,
            title: e.target.value
          }
        }
      });
    }
    if(name==='enheadlines'){
      setNewsdata({
        ...newsdata,
        content:{
          ...newsdata.content,
          en:{
            ...newsdata.content.en,
            headlines: e.target.value
          }
        }
      });
    }
    if(name==='enshort_summary'){
      setNewsdata({
        ...newsdata,
        content:{
          ...newsdata.content,
          en:{
            ...newsdata.content.en,
            short_summary: e.target.value
          }
        }
      });
    }
  }

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const value = inputValue.trim();
      if (value) {
        setHashtags([...hashtags, value]);
        setInputValue('');
      }
    }
  };

  const handleDeleteChip = (index) => {
    const updatedHashtags = [...hashtags];
    updatedHashtags.splice(index, 1);
    setHashtags(updatedHashtags);
  };

  const handleCheckboxChange = (event) => {
    setNewsdata({
      ...newsdata,
      [event.target.name]: event.target.checked,
    });
  };

  const arrowBack = () => {
    navigate(-1);
  };

  useEffect(()=>{
    setLoader(true)
    const formData = {
      "filter": {},
      "sort": "_id",
      "order": 1,
      "page": 0,
      "page_size": 100
    }
      dispatch(getcategories(formData))
      dispatch(gettopics(formData))
      dispatch(getnewsad(formData))
      dispatch(getcity())
      dispatch(getstates())
      dispatch(getnewsbyid(newsid))
        .then((data) => {
          //console.log(data);
        const upData = data.payload.newsdata.data
        setNewsdata(upData);
        setCategoryName(upData?.category);
        setHashtags(upData.tags);
        setMetatitle(upData?.meta_title);
        setMetadesc(upData?.meta_description);
        setTopicsName({
          id: upData?.topics[0]?.id,
          name: upData?.topics[0]?.multilingual_names?.en
        });
        settaNewsAd(upData?.content?.ta?.news_ad);
        setenNewsAd(upData?.content?.en?.news_ad);
        setNewstype(upData?.news_type);
        setFile1(upData?.news_card_images?.ta?.original_url);
        
        setFile2(upData?.news_card_images?.en?.original_url);
        
        setContent1(upData?.content?.ta?.web_content);
        setContent2(upData?.content?.en?.web_content);
        setLoader(false);
        //console.log(newsdata.images.original_url)
      })
      .catch(()=>{
        setLoader(false);
      })
  }, [])


  const handleSubmit = (e) => {
    e.preventDefault();
      const formData = {
        category_id: categoryName?.id, 
        city_id: cityname?.id,
        content:{
          ta:{
            headlines: newsdata.content.ta.headlines,
            title: newsdata.content.ta.title,
            web_content: content1,
            news_ad_id : tanewsad?.id || null
          },
          en:{
            headlines: newsdata.content.en.headlines,
            title: newsdata.content.en.title,
            web_content: content2,
            news_ad_id : ennewsad?.id || null
          }
        },
        is_breaking: newsdata.is_breaking,
        is_international_news: newsdata.is_international_news,
        is_national_news: newsdata.is_national_news,
        is_state_news: newsdata.is_state_news,
        meta_description: metadesc,
        meta_title: metatitle,
        news_source:{
          name: newsdata.news_source.name,
          url: newsdata.news_source.url
        },
        news_type: newsdata?.news_type,
        state_id: statename?.id,
        // state_name:state_name?.name,
        tags: hashtags,
        topics: [
          topicsname?.id
        ]
      };
    const news_id = newsid
    //console.log(news_id)
      setLoader(true);
      dispatch(updatenews({news_id, formData}))
      .then(() => {
        if (file3) {
          const formVata = new FormData();
          formVata.append('image', file3);
          return dispatch(uploadimage({ news_id, formVata }));
        }
      })
      .then(() => {
        setLoader(false);
        // Set the redirect flag to true after successful form submission and image upload
        setShouldRedirect(true);
      })
      .catch((err) => {
        //console.log(err);
        setLoader(false);
      });
      //   .then(() => {
      //   setLoader(false);
      //     //console.log(response?.payload?.data?.data?.data?.id);
      //     if (file3) {
      //       const formVata = new FormData();
      //       formVata.append('image', file3);
      //       dispatch(uploadimage({ news_id, formVata }))
      //       .then(() => {
      //         setLoader(false);
      //          if (formData.news_type === 0) {
      //           //console.log('Navigating to /news/short');
      //           navigate("/news/short");
      //       } else if (formData.news_type === 1) {
      //           //console.log('Navigating to /news/web');
      //           navigate("/news/web");
      //       }
      //       })
      //     }
      //     navigate("/cards");
          
      // })
      //   .catch((err) => {
      //   //console.log(err)
      //   setLoader(false);
      // })
  };

  const handleAction=(news_id, val)=>{
    setLoader(true);
    dispatch(updateaction({news_id, val}))
    .then(() => {
      setLoader(false);
      switch (val) {
        case "revoke":
          dispatch(setStatus("pending"));
        case "reject":
          dispatch(setStatus("rejected"));
        case "approve":
          dispatch(setStatus("approved"));
      }
      navigate('/news')
      setOpen(false);
    })
    .catch(()=>{
      setLoader(false);
    })
  }

  const handleApprove=(news_id)=>{
      setLoader(true);
      const action = "approve";
      dispatch(updateaction({news_id, action}))
      .then(() => {
        setLoader(false);
        setOpen(false);
        navigate("/news");
      })
      .catch(()=>{
        setLoader(false);
      })
  }

  const handleReject=(news_id)=>{
    setLoader(true);
    const action = "reject";
    dispatch(updateaction({news_id, action}))
    .then(() => {
      setLoader(false);
      setOpen(false);
      navigate("/news");
    })
    .catch(()=>{
      setLoader(false);
    })
}

const handlePublish=(news_id)=>{
  setLoader(true);
  dispatch(updatepublish({news_id}))
  .then(() => {
    setLoader(false);
    setOpen(false);
    navigate("/news");
  })
  .catch(()=>{
    setLoader(false);
  })
}

  const handleDelete = (news_id) =>{
    if(news_id){
      setLoader(true)
      dispatch(deletenews({news_id}))
      .then(() => {
        setLoader(false);
        setOpen(false);
        navigate("/news");
      })
      .catch(()=>{
        setLoader(false);
      })
    }
  }


  return (
    <Container maxWidth="fluid" className="content">
        <Box
           component="form"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
        >
            <Grid container spacing={2} sx={{paddingBottom: 2}}>
            <Grid
            item
            xs={12}
            sx={{
              paddingTop: 0,
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >

            <ArrowBackIcon onClick={arrowBack} sx={{ cursor: "pointer" }} />
            <Typography variant="h5"  sx={{fontWeight:'bold'}}>Edit News</Typography>
          </Grid>
          <Grid item xs={4} sx={{display:'flex', gap: 3, alignItems:'center'}}>
          {newsdata?.actions?.map((cd, index)=>{
        return(
          <Button onClick={()=> handleAction(newsdata.id, cd?.action)} variant='contained' color={cd?.negative_action ? 'primary':'success'}>{cd?.label}</Button>
        )
      })}
          {/* {(!newsdata?.is_published && (newsdata?.actions?.filter(ab => ab.action === "approve"))?.length !== 0) && <Button onClick={()=> handleApprove(newsdata.id)} color='secondary' size='large' sx={{marginTop: 3}} variant='contained'> </Button>}
          {(!newsdata?.is_published && (newsdata?.actions?.filter(ab => ab.action === "reject"))?.length !== 0) && <Button onClick={()=> handleReject(newsdata.id)} color='warning' size='large' sx={{marginTop: 3}} variant='contained'>Reject</Button>} */}
          {/* {!newsdata?.is_published ?  <Button onClick={()=> handlePublish(newsdata.id)} color='success' size='large' sx={{marginTop: 3}} variant='contained'>Publish</Button>
          :
          <Button color='success' size='large' sx={{marginTop: 3}} variant='contained'>Un Publish</Button>
          } */}
                <Button onClick={handleOpen} variant='outlined'> Delete</Button>
                <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are you sure you want to delete that news?
          </Typography>
          <Box sx={{display:'flex', justifyContent:'end', mt: 2}}>
          <Button variant='outlined' size="small" sx={{mr: 2}} onClick={handleClose}>No</Button>
          <Button variant='contained' size="small" onClick={() => handleDelete(newsdata.id)}>Yes</Button>
          </Box>
        </Box>
      </Modal>
                </Grid>
                {/* Update btn */}
                <Grid item xs={8} sx={{display:'flex',justifyContent:'end',alignItems:'center',gap:'15px' }}>
                <Button type='submit' sx={{marginTop: 3}} variant='contained'>Update</Button>
               {/* { */}
              {/* //  newstype !== "short" ?( */}
                <Button type='submit' sx={{marginTop: 3}} variant='contained' color='info'>Preview</Button>
               {/* ):null
               } */}
                </Grid>
                <Grid item xs={6}>
                <Autocomplete
      options={catData}
      value={categoryName} 
      getOptionLabel={(option) => `${option.name}`}
      renderInput={(params) => <TextField {...params} sx={{marginBottom: 2}} label="Select Category" variant="outlined" />}
      onChange={(event, newValue) => {
        setCategoryName(newValue);
      }}
    />
                        <Autocomplete
      options={topData}
      value={topicsname} 
      getOptionLabel={(option) => `${option.name}`}
      renderInput={(params) => <TextField {...params} sx={{marginBottom: 2}} label="Select Topics" variant="outlined" />}
      onChange={(event, newValue) => {
        setTopicsName(newValue);
      }}
    />
                    <Autocomplete
      options={cityData}
      value={cityname} 
      getOptionLabel={(option) => `${option.name}`}
      renderInput={(params) => <TextField {...params} sx={{marginBottom: 2}} label="Select City" variant="outlined" />}
      onChange={(event, newValue) => {
        setCityName(newValue);
      }}
    />
                        <Autocomplete
      options={stateData}
      value={statename} 
      getOptionLabel={(option) => `${option.name}`}
      renderInput={(params) => <TextField {...params} sx={{marginBottom: 2}} label="Select State" variant="outlined" />}
      onChange={(event, newValue) => {
        setStateName(newValue);
      }}
    />
                </Grid>
                <Grid item xs={3}>
                {/* <div>
      <TextField
        label="Tags"
        variant="outlined"
        fullWidth
        value={inputValue}
        InputLabelProps={{ required: true }}
        onChange={handleInputChange}
        onKeyDown={handleInputKeyDown}
        placeholder="Press Enter to add tags"
      />
      <div style={{marginTop: 10}}>
        {hashtags.map((tag, index) => (
          <Chip
            key={index}
            label={`#${tag}`}
            onDelete={() => handleDeleteChip(index)}
            sx={{marginRight: 1}}
          />
        ))}
      </div>
    </div> */}
    {newstype !== "short" ?
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox
            checked={newsdata?.is_breaking}
            onChange={handleCheckboxChange}
            name="is_breaking"
          />
        }
        label="Breaking News"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={newsdata.is_international_news}
            onChange={handleCheckboxChange}
            name="is_international_news"
          />
        }
        label="International News"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={newsdata.is_national_news}
            onChange={handleCheckboxChange}
            name="is_national_news"
          />
        }
        label="National News"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={newsdata.is_state_news}
            onChange={handleCheckboxChange}
            name="is_state_news"
          />
        }
        label="State News"
      />
    </FormGroup>
    : null
    }
                </Grid>
                <Grid item xs={3}>
                <TextField sx={{marginBottom: 2}} fullWidth id="outlined-basic" label="News Source Name" variant="outlined" InputLabelProps={{ required: true }}
                value={newsdata?.news_source?.name}
                onChange={(e)=>handleValue(e, 'nsname')}
                />
                                <TextField sx={{marginBottom: 2}} fullWidth id="outlined-basic" label="News Source URL" variant="outlined" InputLabelProps={{ required: true }}
                value={newsdata?.news_source?.url}
                onChange={(e)=>handleValue(e, 'nsurl')}
                />
                </Grid>
                <Grid item xs={6}>
                {newstype !== "short" ? 
                <TextField sx={{marginBottom: 2}} fullWidth id="outlined-basic" label="Meta Title" variant="outlined" InputLabelProps={{ required: true }}
                value={metatitle}
                onChange={(e)=>handleValue(e, 'metatitle')}
                />
                : null
                }
                { newstype !== "short" ? 
                <TextField sx={{marginBottom: 2}} fullWidth id="outlined-basic" label="Meta Description" variant="outlined" InputLabelProps={{ required: true }}
                 value={metadesc}
                onChange={(e)=>handleValue(e, 'metadescription')} 
                />
                : null
                }
                { newstype !== "web" ? 
                                <Autocomplete
      options={adData}
      value={tanewsad} 
      getOptionLabel={(option) => `${option.title}`}
      renderInput={(params) => <TextField {...params} sx={{marginBottom: 2}} label="Select TA News Ad" variant="outlined" />}
      onChange={(event, newValue) => {
        settaNewsAd(newValue);
      }}
    />
    : null}

                {/* only shortnews */}
              
                {
                  newstype === "short"?(
                    <><TextField sx={{ marginBottom: 2 }} fullWidth id="outlined-basic" label="TA Title" variant="outlined" InputLabelProps={{ required: true }}
                    value={newsdata?.content?.ta?.title}
                    onChange={(e) => handleValue(e, 'tntitle')} /><TextField sx={{ marginBottom: 2 }} fullWidth id="outlined-basic" label="TA Description" variant="outlined" InputLabelProps={{ required: true }}
                      value={newsdata?.content?.ta?.headlines}
                      onChange={(e) => handleValue(e, 'tnheadlines')} /><Box sx={{ height: '65%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', border: '2px solid #ccc', borderRadius: '8px', overflow: 'hidden', padding: '16px' }}>
                      <img
                        src={file1}
                        alt="title Image"
                        style={{ maxWidth: '90%', maxHeight: '90%', objectFit: 'cover', borderRadius: '4px' }} />
                    </Box></>
                  ):null
                }
                {/* <TextField sx={{marginBottom: 2}} multiline rows={2} fullWidth id="outlined-basic" label="Short Summary" variant="outlined" InputLabelProps={{ required: true }}
                  value={newsdata?.content?.ta?.short_summary}
                onChange={(e)=>handleValue(e, 'tnshort_summary')}
                />
                <Box sx={{marginBottom: 3}}>
                <Typography variant='body1' sx={{mb: 1}}>Summary Image *</Typography>
                <CloudUpload file={file1} setFile={setFile1} setLoader={setLoader}/>
                </Box> */}
                { newstype !== "short" ? 
                <>
                <Typography variant='body1' sx={{mb: 1}}>TA Content *</Typography>
                <Box sx={{height:'50%'}}>
                <Editor content={content1} setContent={setContent1} placeholder={'Write something...'} />
                </Box>
                </>
                : null
                }
                </Grid>
                <Grid item xs={6}>
                { newstype !== "short" ? 
                <div>
      <TextField
        label="Tags"
        variant="outlined"
        fullWidth
        value={inputValue}
        InputLabelProps={{ required: true }}
        onChange={handleInputChange}
        onKeyDown={handleInputKeyDown}
        placeholder="Press Enter to add tags"
                />
                
      <div style={{marginTop: 10}}>
        {hashtags.map((tag, index) => (
          <Chip
            key={index}
            label={`#${tag}`}
            onDelete={() => handleDeleteChip(index)}
            sx={{marginRight: 1}}
          />
        ))}
      </div>
    </div>
              : null}

    { newstype !== "web" ? 
                <Autocomplete
      options={adData}
      style={{marginTop:'13px'}}
      value={ennewsad} 
      getOptionLabel={(option) => `${option.title}`}
      renderInput={(params) => <TextField {...params} sx={{marginBottom: 2}} label="Select EN News Ad" variant="outlined" />}
      onChange={(event, newValue) => {
        setenNewsAd(newValue);
      }}
    />
              : null}
           <div style={{ position: 'relative' }}>
                {/* webnews uploads */}
              <div style={{ marginBottom: '28px', width: '100%', position: 'absolute', top: newstype === "short" ? '-245px' : '-270px' }}>
                {newsdata && newsdata?.images?.low_res_url ? (
                  <>
                    {/* {newsdata?.images?.low_res_url } */}
                    <CloudUpload setFile={setFile3} setLoader={setLoader} imgUrl={newsdata?.images?.low_res_url} />
                  </>
                 ) : (
                   <CloudUpload file={file3} setFile={setFile3} setLoader={setLoader} />
                 )}
               </div>
             
               {/* EN Content */}
               {newstype !== "short" && (
                 <div style={{ marginTop: '50px' }}> 
                   <Typography variant='body1' sx={{ mb: 1,marginTop:'-35px' }}>EN Content *</Typography>
                   <Box sx={{ height: '50%', position: 'static' }}>
                     <Editor content={content2} setContent={setContent2} placeholder={'Write something...'} />
                   </Box>
                 </div>
                )}
            </div>


              {/* only shortnews */}

              {
                  newstype === "short"?(
                    <><TextField sx={{ marginBottom: 2 }} fullWidth id="outlined-basic" label="EN Title" variant="outlined" InputLabelProps={{ required: true }}
                  value={newsdata?.content?.en?.title}
                  onChange={(e) => handleValue(e, 'entitle')} /><TextField sx={{ marginBottom: 2 }} fullWidth id="outlined-basic" label="EN Description" variant="outlined" InputLabelProps={{ required: true }}
                    value={newsdata?.content?.en?.headlines}
                    onChange={(e) => handleValue(e, 'enheadlines')} />
                    <Box sx={{ height: '65%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', border: '2px solid #ccc', borderRadius: '8px', overflow: 'hidden', padding: '16px' }}>
                    <img
                      src={file2}
                      alt="title Image"
                      style={{ maxWidth: '90%', maxHeight: '90%', objectFit: 'cover', borderRadius: '4px' }} />
                  </Box></>
                  ):null
                }
                 <CardEndpoint shouldRedirect={shouldRedirect}/>
                
                
                {/* <TextField sx={{marginBottom: 2}} multiline rows={2} fullWidth id="outlined-basic" label="Short Summary" variant="outlined" InputLabelProps={{ required: true }}
                  value={newsdata?.content?.en?.short_summary}
                onChange={(e)=>handleValue(e, 'enshort_summary')}
                />
                <Box sx={{marginBottom: 3}}>
                <Typography variant='body1' sx={{mb: 1}}>Summary Image *</Typography>
                <CloudUpload file={file2} setFile={setFile2} setLoader={setLoader}/>
                </Box> */}
                {/* { newstype !== "short" ? 
                <>
                <Typography variant='body1' sx={{mb: 1}}>EN Content *</Typography>
                <Box sx={{height:'50%' ,position:'static'}}>
                <Editor content={content2} setContent={setContent2} placeholder={'Write something...'} />
                </Box>
                </>
                : null} */}
                </Grid>
            </Grid>
       </Box>
    </Container>
  );
}