import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { styled, alpha } from '@mui/material/styles';
import { Button, Container, List, ListItem, ListItemText, Paper, Autocomplete, Box, TextField } from '@mui/material';
import BasicTabs from '../../components/tabs/Tabs1';
import { getnews, getAdminStats, upnews, getcategories, getcity } from '../../slices/news';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import newsService from '../../services/news.service';
import MediaCard from '../../components/card/Card';
import { setStatus } from '../../slices/Status';

const Search = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 100,
  right: 150,
  borderRadius: theme.shape.borderRadius,
  border: '1px solid #000',
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: '#000',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1.5, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '25ch',
    },
  },
}));

const News = ({ setLoader }) => {
  const [results, setResults] = useState([]);
  const [page, setPage] = useState(1);
  const [newstype, setNewstype] = useState("short");
  const status = useSelector((state) => state.status);
  const [query, setQuery] = useState('');
  const { categories: catData } = useSelector((state) => state.news);
  const [category, setCategory] = useState(null);
  const [city, setCity] = useState(null);  
  const { cities: cityData } = useSelector((state) => state.news);
  const { news: newsData } = useSelector((state) => state.news);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [filteredNews, setFilteredNews] = useState([]);

  useEffect(() => {
    setLoader(true);
    const params = {
      status: status,
      news_type: newstype,
      page: page,
      limit: 12,
    };
    if (page === 1) {
      dispatch(getcategories(params))
      dispatch(getcity())
      dispatch(getnews(params))
        .then(() => {
          setLoader(false);
        })
        .catch(() => {
          setLoader(false);
        });
    } else {
      dispatch(upnews(params))
        .then(() => {
          setLoader(false);
        })
        .catch(() => {
          setLoader(false);
        });
    }
  }, [page, newstype, status, category]);
  useEffect(() => {
    // const StatusParams = searchParams.get('status');
    // switch (StatusParams) {
    //   case "approved":
    //     dispatch(setStatus("approved"))
    //   case "rejected":
    //     dispatch(setStatus("rejected"))
    //     case "pending":
    //     dispatch(setStatus("pending"))
    //   default:
    //     dispatch(setStatus("pending"))
    // }
  }, [])
  // Filter the news based on selected category and city
  useEffect(() => {
    //console.log(newsData,"News");
    if (newsData) {
      const filtered = newsData.filter((news) =>
        (!category || news.category?.id === category?.id) &&
        (!city || news.city?.id === city.id)
      );
      setFilteredNews(filtered);
      //console.log(filteredNews,"Filter");
      
    }
  }, [newsData, category, city]);

  const updateNews = () => {
    setPage(page + 1);
  };

  const searchTopics = async (searchQuery) => {
    try {
      const params = {
        query: searchQuery,
        status: 'approved',
        news_type: 'web',
        page: 1,
        limit: 10,
      };
      const response = await newsService.getNews(params);

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = response.data;
      setResults(data.data);
      
    } catch (error) {
      console.error('Error fetching search results:', error);
    }
  };

  const handleInputChange = (e) => {
    const newQuery = e.target.value;
    setQuery(newQuery);
    searchTopics(newQuery);
  };

  return (
    <Container className="content" maxWidth="fluid">
      {/* <BasicTabs setLoader={setLoader} status={status} setStatus={setStatus} setNewstype={setNewstype} newsData={newsData ? newsData : []} />
   
  */}
    

      {/* Pass filteredNews to BasicTabs */}
      <BasicTabs 
        setLoader={setLoader} 
        status={status} 
        setNewstype={setNewstype} 
        newsData={filteredNews}
        catData={catData}
        category={category}
        setCategory={setCategory}
        cityData={cityData}
        city={city}
        setCity={setCity}
      />
      

      <Link to="/news/addnews">
        <Button sx={{ position: 'absolute', top: 100, right: 30 }} variant="contained">
          New News
        </Button>
      </Link>
   

      {/* Search Input */}
      <Search>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase
          placeholder="Search…"
          value={query}
          onChange={handleInputChange}
          inputProps={{ 'aria-label': 'search' }}
        />
      </Search>
      
      {results.length > 0 && (
        <Paper sx={{ position: 'absolute', top: 160, right: 150, width: '30%' }}>
          <List>
            {results.map((result) => (
              <ListItem key={result.id}>
                <ListItemText
                  primary={result.name}
                  secondary={result.description}
                />
              </ListItem>
            ))}
          </List>
        </Paper>
      )}
      <Button variant="contained" onClick={updateNews}>
        Load More
      </Button>
    </Container>
  );
};

export default News;
